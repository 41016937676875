import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { AssessmentModalContent } from './AssessmentModalContent';

export const AssessmentModal = () => {
  return (
    <AppModal
      modalName={Modals.ASSESSMENT}
      scroll="paper"
      maxWidth={false}
      sx={{
        '*': {
          msOverflowStyle: 'initial',
          scrollbarWidth: 'initial',
        },
        '*::-webkit-scrollbar': {
          display: 'block',
          width: 5,
          height: 8,
          backgroundColor: 'neutral10tint1',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'neutral30',
          borderRadius: 5,
        },
      }}>
      <AssessmentModalContent />
    </AppModal>
  );
};
