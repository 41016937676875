import { Close } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';

import { Link } from '@/containers';

export const NASBADisclaimerModal = ({ isOpen, onClose }) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <Box sx={{ p: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h2" sx={{ mb: 2, mr: 2 }}>
            NASBA National Registry of CPE
          </Typography>
          <Close
            sx={{
              cursor: 'pointer',
              color: 'neutral35',
              '&:hover': { color: 'black' },
            }}
            onClick={onClose}
          />
        </Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          National Registry Statement
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          LumiQ is registered with the National Association of State Boards of
          Accountancy (NASBA) as a sponsor of continuing professional education
          on the National Registry of CPE Sponsors. State boards of accountancy
          have the final authority on the acceptance of individual courses for
          CPE credit. Complaints regarding registered sponsors may be submitted
          to the National Registry of CPE Sponsors through its website:&nbsp;
          <Link to="http://nasbaregistry.org" target="_blank">
            www.nasbaregistry.org
          </Link>
        </Typography>
        <Typography variant="body1">Last modified: May 1, 2024</Typography>
      </Box>
    </Dialog>
  );
};
