import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { Markdown, TruncatedBox } from '@/components';

export const EpisodeDescription = ({ episode }) => {
  const { takeaways, description, glossary } = episode;

  const theme = useTheme();

  const sortedGlossary = useMemo(
    () =>
      glossary.slice().sort?.((first, second) => {
        const nameA = first.term.toUpperCase();
        const nameB = second.term.toUpperCase();
        if (nameA === nameB) {
          return 0;
        }

        return nameA < nameB ? -1 : 1;
      }),
    [glossary],
  );

  return (
    <TruncatedBox truncatedContentHeight={280}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box>
          <Typography variant="h3">Episode Description</Typography>
          <Markdown
            sx={{
              ...theme.typography.body1,
              '& p': {
                my: 1,
              },
            }}>
            {description}
          </Markdown>
        </Box>
        {!!takeaways?.length && (
          <Box>
            <Typography variant="h3" sx={{ mb: 1 }}>
              Learning Objectives
            </Typography>
            <Typography variant="body2">
              After completing this episode, you should be able to:
            </Typography>
            <ol>
              {takeaways?.map((takeaway, index) => (
                <li key={index}>
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {takeaway}
                  </Typography>
                </li>
              ))}
            </ol>
          </Box>
        )}
        {!!sortedGlossary?.length && (
          <Box>
            <Typography variant="h3" sx={{ mb: 1 }}>
              Glossary
            </Typography>
            {sortedGlossary?.map((item, idx) => (
              <Box key={idx.toString()} sx={{ mb: 1 }}>
                <Typography display="inline" variant="body2">
                  <b>{item.term}:&nbsp;</b> {item.definition}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </TruncatedBox>
  );
};
