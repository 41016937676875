import { Pill } from '@/components/Pill';
import { convertSecondsToMinutes } from '@/utils';

export const EthicsPill = ({ region, ethicsDuration, showDuration = true }) => {
  return (
    <Pill
      sx={theme => ({
        ...theme.typography.subtitle3,
        color: 'neutral50',
        backgroundColor: 'teal10',
      })}
      size="small"
      label={
        !showDuration || !ethicsDuration
          ? 'ETHICS'
          : region === 'USA'
            ? 'ETHICS'
            : `ETHICS ${convertSecondsToMinutes(ethicsDuration)} mins`
      }
    />
  );
};
