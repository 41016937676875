import { Lock } from '@mui/icons-material';

import { Pill } from '@/components/Pill';

export const PremiumPill = () => {
  return (
    <Pill
      icon={<Lock fontSize="small" color="black" />}
      size="small"
      sx={{
        background: theme => theme.gradients.gold,
        display: 'inline-block',
        mr: 1,
        px: 0.5,
        pt: 0.25,
        borderRadius: '3px',
        '> svg': {
          m: '0 !important',
        },
        'span:empty': {
          display: 'none',
        },
      }}
    />
  );
};
