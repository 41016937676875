import { Box, Typography } from '@mui/material';

import { Button } from '@/components';

export const PlanOption = ({ option, onPersonalPlan }) => {
  return (
    <Box
      sx={{
        width: 380,
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow: '0px 4px 33px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bgcolor: 'white',
      }}>
      <Box
        sx={{
          width: '100%',
          height: 48,
          bgcolor: option.color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="h3" sx={{ color: 'white' }}>
          {option.name}
        </Typography>
      </Box>
      <Box
        component="img"
        alt="plan option icon"
        src={option.image}
        sx={{ width: 140, mt: 4 }}
      />
      <Typography variant="subtitle1" sx={{ ml: 4, mr: 4, mt: 4 }}>
        {option.description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 4,
          mb: 12,
          gap: 1,
        }}>
        <Typography variant="h6">CHOOSE FROM:</Typography>
        {option.bullets.map((bullet, index) => (
          <Typography key={index} variant="subtitle1" color="neutral35">
            {bullet}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 30,
          width: 200,
        }}>
        <Button
          label={option.cta}
          skinny={false}
          onClick={() => {
            if (option.cta.toUpperCase() === 'CONTACT US') {
              window.Intercom?.('show');
            } else {
              onPersonalPlan?.();
            }
          }}
        />
      </Box>
    </Box>
  );
};
