import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';

import { CheckBoxIcon } from './components';

export const CheckboxButton = ({ label, checked, onChange, ...props }) => {
  const { gradients, palette } = useTheme();

  return (
    <Box
      sx={{
        borderRadius: 2.5,
        width: '100%',
        height: 56,
        background: checked ? gradients.primary2 : palette.neutral10tint1,
        color: checked ? 'text.white' : 'text.black',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onChange}>
      <Checkbox
        disableRipple
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        color="default"
        icon={<CheckBoxOutlineBlank sx={styles.icon} />}
        checkedIcon={<CheckBoxIcon sx={[styles.icon, styles.checkedIcon]} />}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
};

const styles = {
  icon: {
    width: 24,
    height: 24,
    backgroundColor: 'white',
    color: 'white',
    borderRadius: 1,
    border: '1px solid #E4E4E4',
  },
  checkedIcon: {
    border: 0,
  },
};
