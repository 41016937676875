import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectPlayerOnSeekMessage } from '@/store';

const VISIBILITY_DURATION = 3500;

export const VideoNotifications = () => {
  const [visible, setVisible] = useState(false);
  const visibilityTimer = useRef(null);
  const onSeekMessage = useSelector(selectPlayerOnSeekMessage);

  useEffect(() => {
    if (onSeekMessage) {
      setVisible(true);
      visibilityTimer.current = setTimeout(
        () => setVisible(false),
        VISIBILITY_DURATION,
      );
    } else {
      setVisible(false);
    }

    return () => clearTimeout(visibilityTimer.current);
  }, [onSeekMessage]);

  const onNotificationClick = () => {
    if (visibilityTimer.current) {
      clearTimeout(visibilityTimer.current);
    }
    if (visible) {
      setVisible(false);
    }
  };

  return (
    <Box sx={[styles.overlay, visible && styles.overlayVisible]}>
      {visible && (
        <Box sx={styles.notificationContainer}>
          <Box sx={styles.notification} onClick={onNotificationClick}>
            {onSeekMessage}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  overlay: {
    cursor: 'auto',
    height: '100%',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.2s ease-in-out',
    width: '100%',
  },
  overlayVisible: {
    opacity: 1,
  },
  notificationContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  notification: {
    backgroundColor: 'neutral35',
    borderRadius: theme => theme.typography.pxToRem(5),
    color: 'white',
    cursor: 'auto',
    fontSize: theme => theme.typography.pxToRem(22),
    margin: theme => theme.typography.pxToRem(20),
    opacity: 0.8,
    padding: theme => theme.typography.pxToRem(20),
    pointerEvents: 'auto',
    textAlign: 'center',
  },
};
