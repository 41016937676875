import { Share, UnfoldLess } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { AnalyticsService } from '@/services';
import {
  playerActions,
  selectActiveEpisodeId,
  selectUserRegion,
  useFetchEpisodeQuery,
  useShowProductFeedbackIfReadyMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

export const TopControls = () => {
  const dispatch = useDispatch();

  const region = useSelector(selectUserRegion);
  const activeEpisodeId = useSelector(selectActiveEpisodeId);
  const { data: episode } = useFetchEpisodeQuery({
    episodeId: activeEpisodeId,
    region,
  });

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  const onMinimize = () => {
    dispatch(playerActions.setExpanded(false));
  };

  return (
    <Box sx={styles.buttonContainer}>
      {episode && (
        <Button
          variant="tertiary"
          label="Share"
          onClick={() => {
            navigator.clipboard.writeText(episode?.deepLink);
            AnalyticsService.episodeShare({ episode });
            SnackbarUtils.success('Link copied to clipboard');
            showProductFeedbackIfReady();
          }}
          startIcon={<Share />}
          colorOverride="white"
          fullWidth={false}
        />
      )}
      <Button
        variant="tertiary"
        label="Minimize"
        onClick={onMinimize}
        startIcon={<UnfoldLess />}
        colorOverride="white"
        fullWidth={false}
      />
    </Box>
  );
};

const styles = {
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: theme => theme.typography.pxToRem(14),
  },
};
