import { Close, Done } from '@mui/icons-material';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@/components';
import { selectUserProfileResult, useUpdatePasswordMutation } from '@/store';
import { SnackbarUtils } from '@/utils';

const INITIAL_STATE = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const UpdatePasswordErrorTypes = {
  BAD_PASSWORD: 'BAD_PASSWORD',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
};

export const UpdatePasswordForm = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState(INITIAL_STATE);
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const { data: profile } = useSelector(selectUserProfileResult);

  const { currentPassword, newPassword, confirmNewPassword } = form;

  const handleChange = event => {
    const { id, value } = event.target;
    if (!isEditing) setIsEditing(true);

    setForm(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const resetForm = () => {
    setIsEditing(false);
    setForm(INITIAL_STATE);
  };

  const newPasswordValidations = [
    { valid: newPassword?.length >= 8, message: 'At least 8 characters long' },
    {
      valid: newPassword?.match(/(?=.*[A-Z])/),
      message: 'At least 1 uppercase letter',
    },
    {
      valid: newPassword?.match(/(?=.*[a-z])/),
      message: 'At least 1 lowercase letter',
    },
    { valid: newPassword?.match(/(?=.*[0-9])/), message: 'At least 1 number' },
    { valid: newPassword?.match(/^\S*$/), message: 'No spaces' },
    { valid: newPassword === confirmNewPassword, message: 'Passwords match' },
  ];

  const isFormValid =
    newPasswordValidations.every(i => i.valid) && !!currentPassword;

  const submitForm = async () => {
    try {
      const response = await updatePassword({
        email: profile?.email,
        oldPassword: currentPassword,
        newPassword,
      }).unwrap();
      resetForm();

      if (!response) {
        SnackbarUtils.error('Previous password entered is incorrect');
        return;
      }
      SnackbarUtils.success('Password updated successfully');
    } catch (error) {
      SnackbarUtils.error('Failed to update password');
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3">Update Password</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              type="password"
              id={'newPassword'}
              label="New Password"
              variant="outlined"
              onChange={handleChange}
              value={newPassword}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              id={'confirmNewPassword'}
              label="Confirm New Password"
              variant="outlined"
              onChange={handleChange}
              value={confirmNewPassword}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="password"
          id={'currentPassword'}
          label="Current Password"
          variant="outlined"
          onChange={handleChange}
          value={currentPassword}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          {newPasswordValidations.map((p, idx) => (
            <Grid key={idx} item xs={6} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                {isEditing && p.valid && (
                  <Done
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(12),
                      color: 'green',
                      mr: 1,
                    }}
                  />
                )}
                {isEditing && !p.valid && (
                  <Close
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(12),
                      color: 'red',
                      mr: 1,
                    }}
                  />
                )}
                <Typography
                  variant="subtitle3"
                  sx={{
                    color: !isEditing ? 'neutral35' : p.valid ? 'green' : 'red',
                  }}>
                  {p.message}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4} justifyContent="flex-end">
          <Grid item xs={6} md={3}>
            <Button
              disabled={!isEditing || isLoading}
              variant="tertiary"
              onClick={resetForm}
              label="Cancel"></Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              disabled={!isEditing || !isFormValid || isLoading}
              onClick={submitForm}
              label="Save"></Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
