import { createTheme } from '@mui/material/styles';
import * as R from 'ramda';

import {
  FONT_SIZE,
  HTML_FONT_SIZE,
  MobileTypography,
  Typography,
} from './typography';

export const PALETTE = {
  aqua: '#00F2FF',
  black: '#000000',
  blueBlack: '#2E384D',
  blueBlackDark: '#2D2D3D',
  blue10: '#DAF2FF',
  blue10tint2: '#E9F7FF',
  blue20: '#9ACEFB',
  blue20tint3: '#D7EBFD',
  blue30: '#1C79C4',
  blue40: '#135A93',
  blue50: '#0A385D',
  gold: '#EFB533',
  goldLight: '#FFE999',
  goldDark: '#F0B536',
  green: '#159553',
  greenLight: '#DCEFE5',
  neutral10: '#ECEFF5',
  neutral10tint1: '#F5F6F9',
  neutral10tint2: '#F9FAFC',
  neutral20: '#ACB0B9',
  neutral20tint3: '#E0E1E3',
  neutral30: '#898F9B',
  neutral35: '#656A73',
  neutral40: '#40444D',
  neutral50: '#21242C',
  orange: '#DF8020',
  red: '#D63649',
  salmon: '#D16161',
  teal10: '#D0F1F1',
  teal20: '#98E2E2',
  teal30: '#00A9B2',
  teal30tint1: '#33BAC1',
  transparent: 'rgba(0,0,0,0)',
  white: '#FFFFFF',
};

const defaultTheme = createTheme({
  palette: {
    primary: { main: PALETTE.teal30 },
    secondary: { main: PALETTE.red },
    error: { main: PALETTE.red },
    success: { main: PALETTE.green },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(', '),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero1: 'h1',
          hero2: 'h1',
          hero3: 'h1',
          subtitle3: 'h6',
          body3: 'p',
          pill: 'span',
          select: 'span',
          tab: 'span',
          button1: 'span',
          button2: 'span',
        },
      },
    },
  },
});
const { breakpoints } = defaultTheme;

const customTheme = {
  palette: {
    ...PALETTE,
    gold: defaultTheme.palette.augmentColor({
      color: {
        main: PALETTE.gold,
      },
    }),
    text: {
      black: PALETTE.neutral50,
      grayLight: '#A1A6B2',
      white: PALETTE.white,
    },
    social: {
      x: '#000',
      facebook: '#1877F2',
      instagram: '#cd486b',
      linkedin: '#0077b5',
    },
  },
  typography: {
    fontSize: FONT_SIZE,
    htmlFontSize: HTML_FONT_SIZE,
    ...R.mapObjIndexed(
      (value: any, key) => ({
        fontFamily: ['Inter', 'sans-serif'].join(', '),
        ...value,
        color: 'inherit',
        [breakpoints.down('md')]: MobileTypography[key],
      }),
      Typography,
    ),
  },
  zIndex: {
    drawer: 1100,
    appBar: 1200,
    player: 1300,
  },
  gradients: {
    orange: `linear-gradient(88deg, ${PALETTE.salmon} 0%, ${PALETTE.orange} 100%)`,
    gray: `linear-gradient(85deg, ${PALETTE.blueBlack} 0%, ${PALETTE.neutral20} 100%)`,
    primary: `linear-gradient(85deg, ${PALETTE.blue40} 0%, rgba(61, 189, 196, 1) 100%)`,
    primary2: 'linear-gradient(90deg, #2176C3 0%, #3AB4C1 100%)',
    success:
      'linear-gradient(85deg, rgba(30, 126, 52, 1) 0%, rgba(49, 222, 88, 1) 100%)',
    warning:
      'linear-gradient(85deg, rgba(191, 72, 72, 1) 0%, rgba(243, 162, 81, 1) 100%)',
    aquaToBlue: `linear-gradient(270deg, ${PALETTE.blue40} 0%, ${PALETTE.teal30} 100%)`,
    blueToAqua: `linear-gradient(268deg, ${PALETTE.teal30} 0%, ${PALETTE.blue40} 100%)`,
    orangeDarkToLight: `linear-gradient(224.42deg, ${PALETTE.orange} 0%, ${PALETTE.salmon} 100%)`,
    forgotPassword: `linear-gradient(45deg, ${PALETTE.blue40} 0%, ${PALETTE.teal30} 100%);`,
    gold: `linear-gradient(90deg, ${PALETTE.goldLight} 0%, ${PALETTE.goldDark} 100%)`,
  },
  customShadows: {
    modalFooter: '0px -2px 4px rgba(48, 56, 75, 0.10)',
    cardFlair: '0px 3px 5px rgba(0, 0, 0, 0.25)',
    button:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  },
  dimensions: {
    header: {
      height: 60,
    },
    regionBanner: {
      height: 38,
    },
    sidebar: {
      width: 240,
    },
    episodeCardCover: {
      width: 192,
      height: 168,
    },
    episodeCardToolbar: {
      height: 42,
    },
    episodeCardText: {
      height: 158,
    },
  },
};

export const theme = createTheme(defaultTheme, customTheme);

export type CustomTheme = typeof customTheme;
