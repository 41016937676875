import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Box, Grid, Hidden } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerActions,
  selectActiveChapterId,
  selectIsPlayerExpanded,
  selectUserRegion,
  useFetchChapterQuery,
} from '@/store';

import { ProgressBar } from '../ProgressBar';
import { VolumeSlider } from '../VolumeSlider';
import { ChapterInfo, MediaControls, ProgressInfo } from './components';

export const MiniPlayer = () => {
  const dispatch = useDispatch();
  const activeChapterId = useSelector(selectActiveChapterId);
  const isExpanded = useSelector(selectIsPlayerExpanded);
  const region = useSelector(selectUserRegion);
  const { isVideo } = useFetchChapterQuery(
    {
      chapterId: activeChapterId,
      region,
    },
    {
      selectFromResult: result => ({
        isVideo: result.data?.type === 'video',
      }),
    },
  );

  const location = { component: EVENT_CONSTANTS.COMPONENT.TRACK_PLAYER };

  const onToggleExpanded = () => {
    dispatch(playerActions.setExpanded(!isExpanded));
  };

  return (
    <Box sx={styles.miniPlayerContainer}>
      <Box sx={styles.progressBarContainer}>
        <ProgressBar referrer={location} />
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <ChapterInfo referrer={location} />
        </Grid>
        <Grid item xs={4} sm={5} md={5} lg={4}>
          <MediaControls />
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={4}>
          <Box sx={styles.playerRight}>
            <ProgressInfo />
            <Hidden lgDown>
              <Box sx={styles.volumeControls}>
                <VolumeSlider referrer={location} />
              </Box>
            </Hidden>
            {isVideo && (
              <Button
                variant="tertiary"
                startIcon={isExpanded ? <UnfoldLess /> : <UnfoldMore />}
                skinny={false}
                fullWidth={false}
                onClick={onToggleExpanded}
                colorOverride="white"
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  miniPlayerContainer: theme => ({
    position: 'relative',
    backgroundColor: 'blueBlack',
    boxShadow: '0 -5px 20px 0 rgba(0,0,0,0.5)',
    color: 'white',
    height: theme.typography.pxToRem(90),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(66),
    },
  }),
  progressBarContainer: theme => ({
    position: 'absolute',
    top: 0,
    left: 90,
    width: 'calc(100% - 90px)',
    [theme.breakpoints.down('sm')]: {
      left: 66,
      width: 'calc(100% - 66px)',
      transform: 'translateY(calc(-50% + 2px))',
    },
  }),
  playerRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    pt: 0.5,
  },
  volumeControls: {
    mr: 2.5,
  },
};
