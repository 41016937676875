import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { ChapterStatus } from '@/constants';
import { selectIsFsmPlayer, selectUserRegion } from '@/store';

import { getChapterStatusText } from './utils';

export const ChapterStatusBadge = ({ userChapter }) => {
  const region = useSelector(selectUserRegion);
  const isFsmPlayer = useSelector(selectIsFsmPlayer);

  return !userChapter ||
    [ChapterStatus.NONE, ChapterStatus.COMPLETED].includes(
      userChapter.status,
    ) ? null : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mb: 0.5,
      }}>
      <Box
        sx={{
          width: 14,
          height: 14,
          borderRadius: '50%',
          backgroundColor:
            userChapter.status === ChapterStatus.VERIFIED ? 'teal30' : 'orange',
        }}
      />
      <Typography
        sx={{
          ml: 1,
          color: 'neutral35',
          fontStyle: 'italic',
        }}>
        {getChapterStatusText({ userChapter, region, isFsmPlayer })}
      </Typography>
    </Box>
  );
};
