import {
  ActionReducerMapBuilder,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import * as R from 'ramda';

import {
  DEFAULT_AUTO_SAVE_INTERVAL,
  DEFAULT_PLAYBACK_SPEED_OPTIONS,
  ListenRejectedReason,
} from '@/constants';
import {
  fetchChapterListenAllowed,
  fetchEpisodeRecentChapterId,
  fetchLastActiveChapter,
  fetchPlaybackSpeedOptions,
  saveChapterProgress,
} from '@/requests';
import { nodeApi } from '@/store/node-api';
import { selectUserRegion } from '@/store/user-api';

import { chaptersApi } from '../chapters-api';
import { RootState } from '../store';
import { rtkQueryError } from '../store.utils';
import { PLAYER_SLICE_KEY } from './player.constants';
import { playerThunks } from './player.thunks';
import {
  ChapterListenAllowedResponse,
  LastActiveChapterResponse,
  PlayerState,
  RejectWithReason,
  SaveChapterProgressPayload,
  SaveChapterProgressResponse,
} from './player.types';

const {
  episodePlayPressed,
  changeActiveChapter,
  updatePlayerProgress,
  savePlayerProgressLegacy,
  savePlayerProgressFsm,
  seekToPosition: seekToPositionThunk,
  playPlayer,
  pausePlayer,
  resumeLastSessionFsm,
} = playerThunks;

const initialState: PlayerState = {
  isTrackLoaded: false,
  isChangingChapter: false,
  isAttemptingToChangeChapter: false,
  isPlaybackRejected: false,
  playbackRejectedChapter: undefined,
  activeChapter: undefined,
  location: undefined,
  referrer: undefined,
  isBuffering: false,
  isPlaying: false,
  progress: {
    position: undefined,
    buffered: undefined,
  },
  isExpanded: false,
  isVideoFullscreen: false,
  seekToPosition: undefined,
  isSeeking: false,
  lastSavedProgress: 0,
  isSavingProgress: false,
  playbackSpeedOptions: DEFAULT_PLAYBACK_SPEED_OPTIONS,
  autoSaveInterval: DEFAULT_AUTO_SAVE_INTERVAL,
  volume: 50,
  speed: 1,
  messages: {
    onSeek: '',
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<PlayerState>) => {
  builder.addCase(episodePlayPressed.pending, state => {
    state.isAttemptingToChangeChapter = true;
  });
  builder.addCase(changeActiveChapter.pending, state => {
    state.isChangingChapter = true;
  });
  builder.addCase(updatePlayerProgress.pending, (state, { meta }) => {
    state.progress = meta.arg.progress;
  });
  builder.addCase(savePlayerProgressLegacy.pending, state => {
    state.isSavingProgress = true;
  });
  builder.addCase(savePlayerProgressLegacy.fulfilled, (state, { payload }) => {
    state.lastSavedProgress = payload.position || 0;
  });
  builder.addCase(savePlayerProgressFsm.pending, state => {
    state.isSavingProgress = true;
  });
  builder.addCase(savePlayerProgressFsm.fulfilled, (state, { payload }) => {
    state.lastSavedProgress = payload.position || 0;
  });
  builder.addCase(seekToPositionThunk.pending, state => {
    state.isSeeking = true;
  });
  builder.addCase(seekToPositionThunk.fulfilled, (state, { payload }) => {
    state.lastSavedProgress = 0;

    if (payload) state.progress.position = payload.position;
  });
  builder.addCase(playPlayer.fulfilled, state => {
    state.isPlaying = true;
  });
  builder.addCase(pausePlayer.fulfilled, state => {
    state.isPlaying = false;
  });
  builder.addMatcher(
    isAnyOf(changeActiveChapter.fulfilled, resumeLastSessionFsm.fulfilled),
    (state, { payload }) => {
      if (!payload) return;

      const {
        activeChapter,
        autoPlay = true,
        seekToPosition,
        isExpanded,
        isVideoFullscreen,
        location,
        referrer,
      } = payload;

      state.isTrackLoaded = initialState.isTrackLoaded;
      state.isChangingChapter = initialState.isChangingChapter;
      state.isAttemptingToChangeChapter =
        initialState.isAttemptingToChangeChapter;
      state.isPlaybackRejected = initialState.isPlaybackRejected;
      state.playbackRejectedChapter = initialState.playbackRejectedChapter;
      state.isBuffering = initialState.isBuffering;
      state.progress = initialState.progress;
      state.lastSavedProgress = initialState.lastSavedProgress;
      state.isSavingProgress = initialState.isSavingProgress;
      state.messages = initialState.messages;

      state.activeChapter = activeChapter;
      state.seekToPosition = seekToPosition;
      state.isPlaying = autoPlay;
      state.isSeeking = !!seekToPosition;
      state.isExpanded = isExpanded || false;
      state.isVideoFullscreen = isVideoFullscreen || false;
      state.location = location;
      state.referrer = referrer;
    },
  );
  builder.addMatcher(
    isAnyOf(
      savePlayerProgressLegacy.rejected,
      savePlayerProgressLegacy.fulfilled,
      savePlayerProgressFsm.rejected,
      savePlayerProgressFsm.fulfilled,
    ),
    state => {
      state.isSavingProgress = false;
    },
  );
  builder.addMatcher(
    isAnyOf(seekToPositionThunk.rejected, seekToPositionThunk.fulfilled),
    state => {
      state.isSeeking = false;
      state.seekToPosition = undefined;
    },
  );
  builder.addMatcher(
    isAnyOf(changeActiveChapter.rejected, changeActiveChapter.fulfilled),
    (state, { payload }) => {
      state.isChangingChapter = false;
      const rejectedPayload = payload as RejectWithReason;
      if (rejectedPayload.reason === ListenRejectedReason.NEED_REDEEM) {
        state.isPlaybackRejected = true;
        state.playbackRejectedChapter = {
          episodeId: rejectedPayload.episodeId as string,
          chapterId: rejectedPayload.chapterId as string,
        };
      }
    },
  );
  builder.addMatcher(
    isAnyOf(episodePlayPressed.rejected, episodePlayPressed.fulfilled),
    state => {
      state.isAttemptingToChangeChapter = false;
      state.isChangingChapter = false;
    },
  );
};

export const playerSlice = createSlice({
  name: PLAYER_SLICE_KEY,
  initialState,
  reducers: {
    setVolume: (state, { payload }: { payload: number }) => {
      state.volume = payload;
    },
    setBuffering: (state, { payload }: { payload: boolean }) => {
      state.isBuffering = payload;
    },
    setTrackLoaded: (state, { payload }: { payload: boolean }) => {
      state.isTrackLoaded = payload;
    },
    setSeekToPosition: (state, { payload }: { payload: number }) => {
      state.seekToPosition = payload;
    },
    setAutoSaveInterval: (state, { payload }: { payload: number }) => {
      state.autoSaveInterval = payload;
    },
    setPlaybackSpeedOptions: (state, { payload }: { payload: number[] }) => {
      state.playbackSpeedOptions = payload;
    },
    setPlaybackSpeed: (state, { payload }: { payload: number }) => {
      state.speed = payload;
    },
    clearActiveChapter: state => ({
      ...initialState,
      ...R.pick(
        ['volume', 'speed', 'playbackSpeedOptions', 'autoSaveInterval'],
        state,
      ),
    }),
    setExpanded: (state, { payload }: { payload: boolean }) => {
      state.isExpanded = payload;
    },
    setVideoFullscreen: (state, { payload }: { payload: boolean }) => {
      state.isVideoFullscreen = payload;
    },
  },
  extraReducers,
});

export const playerApi = nodeApi.injectEndpoints({
  endpoints: builder => ({
    fetchLastActiveChapter: builder.query<LastActiveChapterResponse, void>({
      query: fetchLastActiveChapter,
      transformResponse: (response: any) => response.data,
    }),
    fetchEpisodeRecentChapterId: builder.query<string, { episodeId: string }>({
      query: fetchEpisodeRecentChapterId,
      transformResponse: (response: any) => response.data,
    }),
    fetchChapterListenAllowed: builder.query<
      ChapterListenAllowedResponse,
      { chapterId: string }
    >({
      queryFn: async (
        { chapterId },
        { getState, dispatch },
        extraOptions,
        baseQuery,
      ) => {
        try {
          const chapter = await dispatch(
            chaptersApi.endpoints.fetchChapter.initiate(
              {
                chapterId,
                region: selectUserRegion(getState() as RootState),
              },
              {
                subscribe: false,
              },
            ),
          ).unwrap();

          if (!chapter) return { data: { response: false } };

          if (chapter.isTrailer) return { data: { response: true } };

          const { data, error } = await baseQuery(
            fetchChapterListenAllowed({ chapterId }),
          );

          if (error) return { error };

          return data as any;
        } catch (error) {
          return rtkQueryError(error);
        }
      },
    }),
    fetchPlaybackSpeedOptions: builder.query<number[], void>({
      query: fetchPlaybackSpeedOptions,
      transformResponse: (response: any) => response.data,
    }),
    saveChapterProgress: builder.mutation<
      SaveChapterProgressResponse,
      SaveChapterProgressPayload
    >({
      query: saveChapterProgress,
    }),
  }),
});

export const playerActions = playerSlice.actions;
export const { reducer: playerReducer } = playerSlice;
export const { useFetchPlaybackSpeedOptionsQuery } = playerApi;
export const PLAYER_SLICE_INITIAL_STATE = initialState;
