import { Close, Share } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchEpisodeChapterProgress } from 'src/hooks/useFetchEpisodeChapterProgress';

import { Button, FeedbackRatings } from '@/components';
import { graphDone } from '@/images';
import { AnalyticsService } from '@/services';
import {
  selectActiveModalParams,
  selectEpisodeProgress,
  selectUserRegion,
  uiActions,
  useFetchChapterQuery,
  useFetchEpisodeQuery,
  userEpisodesSelectors,
  useSubmitEpisodeFeedbackMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

const reasonList = [
  'Audio Quality',
  'Educational Value',
  'Guest',
  'Entertainment Value',
  'Host Quality',
  'Other',
];
const CURRENT_FEEDBACK_VERSION = 'v4';

export const EpisodeFeedbackModalContent = () => {
  const dispatch = useDispatch();

  const { episodeId, chapterId: activeChapterId } =
    useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );

  const { data: episode, isLoading } = useFetchEpisodeQuery(
    { episodeId, region },
    { skip: !episodeId },
  );
  const { data: chapter } = useFetchChapterQuery(
    { chapterId: activeChapterId, region },
    { skip: !activeChapterId },
  );

  useFetchEpisodeChapterProgress(episodeId);
  const episodeChaptersProgress = useSelector(state =>
    selectEpisodeProgress(state, episodeId),
  );
  const chapterProgress = episodeChaptersProgress?.find(
    ({ chapterId }) => chapterId === activeChapterId,
  );
  const episodePlayedDuration = userEpisode?.playedDuration || 0;

  const [rating, setRating] = useState(null);
  const [ratingReasons, setRatingReasons] = useState(
    reasonList.reduce((acc, reason) => ({ ...acc, [reason]: false }), {}),
  );
  const [textFeedback, setTextFeedback] = useState('');
  const [otherFeedback, setOtherFeedback] = useState('');
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [submitFeedbackRequest, { isLoading: isSubmitting }] =
    useSubmitEpisodeFeedbackMutation();

  const submitFeedback = async () => {
    const reasonsArray = R.pipe(
      R.filter(a => a),
      R.keys,
      R.map(reason => {
        if (reason === 'Other') {
          return `Other: ${otherFeedback}`;
        }
        return reason;
      }),
    )(ratingReasons);

    await submitFeedbackRequest({
      episodeId,
      episodePlayedDuration,
      feedback: {
        episodeRating: rating + 1,
        ratingReason: R.pipe(
          R.filter(a => a),
          R.keys,
          R.map(reason => {
            if (reason === 'Other') {
              return `Other: ${otherFeedback}`;
            }
            return reason;
          }),
        )(ratingReasons),
        textFeedback,
      },
    });

    AnalyticsService.episodeFeedback({
      episodeRating: rating + 1,
      episode,
      chapter,
      chapterProgress,
      episodePlayedDuration,
      textFeedback,
      ratingReasons: reasonsArray.join(','),
      version: CURRENT_FEEDBACK_VERSION,
    });

    setIsFeedbackSubmitted(true);
  };

  const onClose = () => {
    dispatch(uiActions.closeActiveModal());
  };

  const onShare = () => {
    navigator.clipboard.writeText(episode.deepLink);
    AnalyticsService.episodeShare({ episode });
    SnackbarUtils.success('Link copied to clipboard');
  };

  if (isFeedbackSubmitted)
    return (
      <Box sx={styles.thanksContainer}>
        <Box sx={styles.closeIcon} onClick={onClose}>
          <Close />
        </Box>
        <Typography sx={styles.header} variant="h1">
          Thank you for your feedback!
        </Typography>
        <Box component="img" alt="" sx={styles.thanksImg} src={graphDone} />
        <Typography sx={styles.textFeedback}>
          Your information will help us make LumiQ better for you.
        </Typography>
        <Button
          startIcon={<Share />}
          label="Share Episode"
          onClick={onShare}
          sx={{ mb: 1 }}
        />
        <Button variant="tertiary" onClick={onClose} label="Close" />
      </Box>
    );

  return isLoading ? null : (
    <Box sx={styles.container}>
      <IconButton sx={{ ml: 'auto' }} onClick={onClose}>
        <Close />
      </IconButton>

      <Box sx={styles.titleContainer}>
        <Typography variant="h1" sx={{ fontWeight: 600, mb: 3 }}>
          How likely are you to recommend this episode?
        </Typography>
      </Box>
      <Box>
        <img
          src={episode?.cover}
          alt="Episode cover"
          style={{
            height: 200,
          }}
        />
      </Box>
      <Typography variant="subtitle1">{episode?.name}</Typography>
      <Box sx={{ width: '90%', alignSelf: 'center' }}>
        <Typography variant="subtitle3" sx={{ lineHeight: 2 }}>
          {episode?.speakers.map(speaker => speaker.name).join(', ')}
        </Typography>
      </Box>
      <Typography variant="subtitle3" sx={{ mt: 2 }}>
        (1 = not at all, 5 = shout it from the mountain top!)
      </Typography>
      <FeedbackRatings
        rating={rating}
        onRatingChange={index => setRating(index)}
        ratingScale={5}
      />
      {!R.isNil(rating) && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: '400' }}>
            What were some of the reasons you gave this episode a {rating + 1}?
          </Typography>
          <Typography
            variant="subtitle3"
            sx={{ fontWeight: '400', color: 'neutral35' }}>
            (Check all that apply)
          </Typography>
          <Box sx={styles.reasonsContainer}>
            {reasonList.map(reason => (
              <Box
                key={reason}
                sx={styles.reason}
                onClick={() =>
                  setRatingReasons(prev => ({
                    ...prev,
                    [reason]: !prev[reason],
                  }))
                }>
                <Checkbox checked={ratingReasons[reason]} />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 400, textAlign: 'left' }}>
                  {reason}
                </Typography>
              </Box>
            ))}
          </Box>
          {ratingReasons['Other'] && (
            <>
              <Typography variant="subtitle2" sx={{ fontWeight: '400' }}>
                Please type out what the "other" reason is.
              </Typography>
              <TextField
                variant="standard"
                multiline
                maxRows={4}
                placeholder="Click here and start typing"
                value={otherFeedback}
                onChange={e => setOtherFeedback(e.target.value)}
                InputProps={{
                  style: {
                    fontSize: '14px',
                    lineHeight: '14px',
                  },
                }}
                sx={styles.textField}
              />
            </>
          )}
          <Typography variant="subtitle2" sx={{ fontWeight: '400', mt: 2 }}>
            What did you like or dislike about this episode?
          </Typography>
          <TextField
            variant="standard"
            multiline
            maxRows={4}
            placeholder="Click here and start typing"
            value={textFeedback}
            onChange={e => setTextFeedback(e.target.value)}
            InputProps={{
              style: {
                fontSize: '14px',
                lineHeight: '14px',
              },
            }}
            sx={styles.textField}
          />
        </>
      )}
      <Button
        variant="primary"
        label="Submit"
        fullWidth={false}
        disabled={isSubmitting || R.isNil(rating)}
        onClick={submitFeedback}
      />
    </Box>
  );
};

const styles = {
  container: {
    p: {
      sm: 4,
      xs: 1,
    },
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reasonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: {
      sm: '70%',
    },
    m: {
      sm: 3,
    },
  },
  reason: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    cursor: 'pointer',
  },
  textField: {
    bgcolor: 'neutral10tint1',
    borderRadius: 1,
    width: {
      sm: '70%',
    },
    mt: 1,
    mb: 1,
    p: 2,
  },
  thanksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 4,
  },
  header: {
    mb: 1,
    textAlign: 'center',
  },
  textFeedback: {
    mx: 4,
    mb: 4,
    color: 'neutral35',
  },
  thanksImg: {
    width: 150,
  },
  closeIcon: {
    cursor: 'pointer',
    ml: 'auto',
  },
};
