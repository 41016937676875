import { AccessTime } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { EthicsPill } from '@/components';
import {
  getIsEpisodeEthics,
  selectUserRegion,
  useFetchCategoriesQuery,
} from '@/store';
import { convertSecondsToHoursMinutes } from '@/utils';

export const EpisodeHeader = ({ episode }) => {
  const userRegion = useSelector(selectUserRegion);

  const { data: categories } = useFetchCategoriesQuery(
    { categoryIds: episode.categories, region: userRegion },
    {
      skip: !episode.categories?.length || userRegion !== 'CAN',
      selectFromResult: result => {
        return {
          ...result,
          data: result.data?.filter(
            category => category.name !== 'Ethics' && category.name !== 'Video',
          ),
        };
      },
    },
  );

  const isEpisodeEthics = getIsEpisodeEthics({ episode, userRegion });

  const hideDetails =
    episode.isArchived || (userRegion === 'USA' && episode.region !== 'USA');
  const showDuration = userRegion === 'USA';
  const showEthicsPill = isEpisodeEthics;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h2">{episode.name}</Typography>
      <Typography variant="body2">{episode.hook}</Typography>
      {!hideDetails && (
        <>
          {(showDuration || showEthicsPill) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'neutral35',
              }}>
              {showDuration && (
                <>
                  <AccessTime sx={{ width: 16, height: 16, mr: 0.5 }} />
                  <Typography variant="body3" mr={1}>
                    {convertSecondsToHoursMinutes(episode.duration)}
                  </Typography>
                </>
              )}
              {showEthicsPill && (
                <EthicsPill
                  region={userRegion}
                  ethicsDuration={episode.totalEthics}
                />
              )}
            </Box>
          )}

          {userRegion === 'CAN' && categories?.length > 0 && (
            <Typography variant="body3">
              <b>Categories: </b>
              {categories.map(c => c.name).join(', ')}
            </Typography>
          )}
          {userRegion === 'USA' && (
            <Typography variant="body3">
              <b>Field of Study:</b> {episode.CPEDetails?.fieldOfStudy}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
