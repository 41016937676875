import { Close } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Modals } from '@/constants';
import { qrCode } from '@/images';
import { uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const AppDownloadModal = () => {
  const dispatch = useDispatch();

  return (
    <AppModal modalName={Modals.APP_DOWNLOAD} scroll="body">
      <Paper sx={styles.modalBody}>
        <Box>
          <IconButton
            aria-label="close"
            edge="end"
            sx={styles.closeButton}
            onClick={() => {
              dispatch(uiActions.closeActiveModal());
            }}>
            <Close />
          </IconButton>
        </Box>
        <Typography sx={styles.header} variant="h1">
          Download the Mobile App
        </Typography>
        <Typography sx={styles.subtext}>
          Use the QR code below to download the LumiQ
        </Typography>
        <Typography sx={styles.subtext}>app and listen on the go!</Typography>
        <Box component="img" sx={styles.qrImage} src={qrCode} alt="qr code" />
      </Paper>
    </AppModal>
  );
};

const styles = {
  modalBody: {
    '&:focus': {
      outline: 'none',
    },
    background: theme => theme.gradients.blueToAqua,
    p: {
      md: 10,
      xs: 2,
    },
  },
  header: {
    textAlign: 'center',
    color: 'white',
    pt: 4,
    pb: 1,
  },
  subtext: {
    color: 'white',
    textAlign: 'center',
  },
  qrImage: {
    width: theme => theme.typography.pxToRem(182),
    pt: 4,
    display: 'block',
    margin: 'auto',
  },
  closeButton: {
    color: 'white',
    position: 'absolute',
    top: 0,
    right: 0,
    m: 3,
  },
  icon: {
    minWidth: theme => theme.typography.pxToRem(35),
    '& svg': {
      fontSize: theme => theme.typography.pxToRem(24),
    },
  },
};
