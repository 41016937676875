import { useGrowthBook } from '@growthbook/growthbook-react';
import { useSelector } from 'react-redux';

import { selectSessionUserId } from '@/store';

export const useGrowthbookReady = () => {
  const userId = useSelector(selectSessionUserId);
  const gb = useGrowthBook();
  return !userId || !!Object.keys(gb.getAttributes()).length;
};
