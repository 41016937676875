import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChapterStatus } from '@/constants';
import { useFetchEpisodeChapterProgress } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectActiveModalParams,
  selectChapterProgress,
  selectIsFsmPlayer,
  selectIsPlayerPlaying,
  selectUserRegion,
  uiActions,
  useAnswerQuizMutation,
  useFetchEpisodeChaptersQuery,
  useFetchEpisodePendingQuizzesQuery,
  userEpisodesSelectors,
  useSubmitQuizLegacyMutation,
} from '@/store';
import { convertSecondsToMinutes, SnackbarUtils } from '@/utils';

import { ChapterQuizModalContent as ChapterQuizModalContentComponent } from './ChapterQuizModalContent.component';

export const ChapterQuizModalContent = () => {
  const { episodeId, chapterId, showFirstIncompleteQuiz } =
    useSelector(selectActiveModalParams) || {};
  const region = useSelector(selectUserRegion);
  const isFsmPlayerUser = useSelector(selectIsFsmPlayer);
  const isPlayerPlaying = useSelector(selectIsPlayerPlaying);
  const activeChapterId = useSelector(selectActiveChapterId);
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );

  const [selectedPageIndex, setSelectedPageIndex] = useState(null);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);

  const dispatch = useDispatch();

  const { data: chapters, isLoading: isChaptersLoading } =
    useFetchEpisodeChaptersQuery(
      { episodeId, region },
      {
        skip: !episodeId,
      },
    );

  const {
    data: episodeQuizzes,
    isLoading: isQuizzesLoading,
    refetch: refetchQuizzes,
  } = useFetchEpisodePendingQuizzesQuery(
    {
      episodeId,
      region,
    },
    {
      skip: !episodeId,
    },
  );

  useFetchEpisodeChapterProgress(episodeId);

  const [submitQuizLegacy, { isLoading: isSubmitLoadingLegacy }] =
    useSubmitQuizLegacyMutation();
  const [submitQuizFsm, { isLoading: isSubmitLoadingFsm }] =
    useAnswerQuizMutation();

  const isSubmitLoading = isSubmitLoadingFsm || isSubmitLoadingLegacy;

  const modalPages = useMemo(() => {
    const formattedArray = [];
    if (!chapters || !episodeQuizzes) return formattedArray;
    chapters
      ?.filter(chapter => !chapter.isTrailer)
      .forEach(chapter => {
        const chapterQuizzes =
          episodeQuizzes?.filter(q => q.chapterId === chapter.chapterId) || [];
        if (!chapterQuizzes.length) {
          formattedArray.push({
            chapter,
            quiz: null,
            quizCountLabel: null,
          });
          return;
        }
        chapterQuizzes.forEach((quiz, index) => {
          formattedArray.push({
            chapter,
            quiz,
            quizCountLabel:
              chapterQuizzes.length > 1
                ? `${index + 1}/${chapterQuizzes.length}`
                : null,
          });
        });
      });
    return formattedArray;
  }, [chapters, episodeQuizzes]);

  const selectedPage = modalPages[selectedPageIndex];
  const { chapter, quiz } = selectedPage || {};

  const userChapter = useSelector(state =>
    selectChapterProgress(state, {
      episodeId: chapter?.episodeId,
      chapterId: chapter?.chapterId,
    }),
  );
  const isChapterCompleted = !!userChapter?.completed;

  useEffect(() => {
    // Initialize starting page
    if (!R.isNil(selectedPageIndex) || !modalPages.length) return;

    let index = 0;
    if (showFirstIncompleteQuiz) {
      const firstIncompleteQuizIndex = modalPages.findIndex(
        page => page.quiz && !page.quiz.completed,
      );
      if (firstIncompleteQuizIndex > -1) index = firstIncompleteQuizIndex;
    } else if (chapterId) {
      const chapterIndices = modalPages.reduce((acc, page, i) => {
        if (page.chapter.chapterId === chapterId) acc.push(i);
        return acc;
      }, []);
      const firstIncompleteQuizIndex = chapterIndices.find(
        i => !modalPages[i].quiz?.completed,
      );
      if (firstIncompleteQuizIndex > -1) {
        index = firstIncompleteQuizIndex;
      } else if (chapterIndices[0] > -1) {
        index = chapterIndices[0];
      }
    }

    setSelectedPageIndex(index);
  }, [modalPages, chapterId, selectedPageIndex, showFirstIncompleteQuiz]);

  useEffect(() => {
    setSelectedAnswerIndex(null);
  }, [selectedPageIndex]);

  const isDataLoading = isChaptersLoading || isQuizzesLoading;

  const onPreviousPage = () => {
    if (selectedPageIndex === 0) return;
    setSelectedPageIndex(selectedPageIndex - 1);
    setSelectedAnswerIndex(null);
  };

  const onNextPage = () => {
    if (selectedPageIndex === modalPages.length - 1) return;
    setSelectedPageIndex(selectedPageIndex + 1);
    setSelectedAnswerIndex(null);
  };

  const onClose = () => {
    dispatch(uiActions.closeActiveModal());
  };

  const onSubmitQuizLegacy = async () => {
    const payload = {
      episodeId: chapter.episodeId,
      quizId: quiz.quizId,
      answerId: quiz.answers[selectedAnswerIndex].id,
      region,
    };
    const { data: pass } = await submitQuizLegacy(payload);

    if (pass) {
      SnackbarUtils.success(
        region === 'USA'
          ? 'Well done, you got it right!'
          : `Well done! You've earned ${convertSecondsToMinutes(chapter.duration ?? 0)} minutes of CPD!`,
      );
    } else {
      SnackbarUtils.warning('Sorry, wrong answer. Please try again.');
    }

    AnalyticsService.quizAttempt({
      chapterId: chapter.chapterId,
      chapterName: chapter.name,
      quizId: payload.quizId,
      answer: payload.answerId,
      pass,
    });
  };

  const onSubmitQuizFsm = async () => {
    const payloadLegacy = {
      episodeId: chapter.episodeId,
      quizId: quiz.quizId,
      answerId: quiz.answers[selectedAnswerIndex].id,
      region,
    };

    // validate via old endpoint for now
    const submitQuizLegacyResponse = await submitQuizLegacy(payloadLegacy);
    if ('error' in submitQuizLegacyResponse) {
      SnackbarUtils.warning(
        'Failed to submit quiz, please try again or contact support.',
      );
      return;
    }
    const { data: isAnswerCorrect } = submitQuizLegacyResponse;

    if (!isAnswerCorrect) {
      SnackbarUtils.warning('Sorry, wrong answer. Please try again.');
      return;
    }

    const payload = {
      sessionId: userEpisode.sessionId,
      chapterId: chapter.chapterId,
    };

    const { error } = await submitQuizFsm(payload);
    if (error) {
      SnackbarUtils.warning(
        'Failed to submit quiz, please try again or contact support.',
      );
      return;
    }

    refetchQuizzes();

    SnackbarUtils.success(
      region === 'USA'
        ? 'Well done, you got it right!'
        : `Well done! You've earned ${convertSecondsToMinutes(chapter?.duration ?? 0)} minutes of CPD!`,
    );

    AnalyticsService.quizAttempt({
      chapterId: chapter.chapterId,
      chapterName: chapter.name,
      quizId: quiz.quizId,
      answer: quiz.answers[selectedAnswerIndex].id,
      pass: isAnswerCorrect,
    });
  };

  const onSubmitQuiz = isFsmPlayerUser ? onSubmitQuizFsm : onSubmitQuizLegacy;

  const onPlayChapter = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId: chapter.episodeId,
          chapterId: chapter.chapterId,
        },
        referrer: {
          page: EVENT_CONSTANTS.COMPONENT.CHAPTER_QUIZ_MODAL,
          button: EVENT_CONSTANTS.BUTTON.PLAY_CHAPTER,
        },
      }),
    );
  };

  const isQuizCompleted = isFsmPlayerUser
    ? userChapter?.status === ChapterStatus.VERIFIED
    : !!quiz?.completed;

  const isChapterPlaying =
    isPlayerPlaying && activeChapterId === chapter?.chapterId;

  return (
    <ChapterQuizModalContentComponent
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onSelectAnswer={setSelectedAnswerIndex}
      onSubmitQuiz={onSubmitQuiz}
      onPlayChapter={onPlayChapter}
      onClose={onClose}
      totalPageCount={modalPages.length}
      selectedPageIndex={selectedPageIndex}
      selectedPage={selectedPage}
      selectedAnswerIndex={selectedAnswerIndex}
      isChapterCompleted={isChapterCompleted}
      isChapterPlaying={isChapterPlaying}
      isQuizCompleted={isQuizCompleted}
      isDataLoading={isDataLoading}
      isSubmitLoading={isSubmitLoading}
      region={region}
    />
  );
};
