import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  Alert,
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnswerBox, Button } from '@/components';
import { EpisodeStatus } from '@/constants';
import { assessmentFail, assessmentPass } from '@/images';
import { AnalyticsService, EVENTS } from '@/services';
import {
  selectActiveModalParams,
  uiActions,
  useFetchEpisodeQuery,
  userEpisodesSelectors,
} from '@/store';
import { useFetchEpisodeAssessmentQuery } from '@/store/assessments-api';
import { useSubmitUserAssessmentMutation } from '@/store/user-assessments-api';

import { AssessmentQuestionSkeleton } from './AssessmentQuestionSkeleton';

export const AssessmentModalContent = () => {
  const dispatch = useDispatch();
  const { episodeId } = useSelector(selectActiveModalParams) || {};
  const antiCheatingActive = useFeatureValue('assessment-anti-cheating', true);
  const { data: assessment, isLoading: isAssessmentLoading } =
    useFetchEpisodeAssessmentQuery(
      { episodeId, antiCheatingActive },
      { skip: !episodeId },
    );

  const { data: episode } = useFetchEpisodeQuery(
    {
      episodeId,
      region: 'USA',
    },
    { skip: !episodeId },
  );
  const [answers, setAnswers] = useState([]);
  const [
    submitUserAssessment,
    { data: submitUserAssessmentResponse, reset, isLoading: isSubmitLoading },
  ] = useSubmitUserAssessmentMutation();

  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );
  const isCompleted = userEpisode?.status === EpisodeStatus.VERIFIED;

  useEffect(() => {
    if (assessment && assessment.questions?.length > 0) {
      setAnswers(assessment.questions.map(question => null));
    }
  }, [assessment]);

  if (!assessment) {
    return (
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    );
  }

  if (submitUserAssessmentResponse) {
    return (
      <DialogContent
        tabIndex={0}
        sx={{
          width: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          outlineColor: 'transparent',
        }}>
        {submitUserAssessmentResponse.pass && (
          <>
            <Typography variant="h1" sx={{ fontWeight: 600 }}>
              Well Done!
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              You passed with a score of {submitUserAssessmentResponse?.score}%
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You have earned {episode.CPEDetails.credits} credit
              {episode.CPEDetails.credits !== 1 && 's'}
            </Typography>
            <Box mt={2}>
              <img src={assessmentPass} alt="pass" style={{ maxWidth: 150 }} />
            </Box>
            {episode.isEthics && (
              <>
                <Typography variant="subtitle1" mt={2}>
                  This episode has ethics qualified chapters:
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  You obtained {episode.CPEDetails.credits} ethics credit
                </Typography>
              </>
            )}
            <Box mt={2}>
              <Button
                variant="tertiary"
                label="Close"
                fullWidth={false}
                onClick={() => {
                  dispatch(uiActions.closeActiveModal());
                  reset();
                }}
              />
            </Box>
          </>
        )}
        {!submitUserAssessmentResponse.pass && (
          <>
            <Typography variant="h1" sx={{ fontWeight: 600 }}>
              Let&apos;s try one more time
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              You need at least {assessment.passRate}% to pass
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              and you got only got {submitUserAssessmentResponse?.score}%
            </Typography>
            <Box mt={2}>
              <img src={assessmentFail} alt="fail" style={{ maxWidth: 240 }} />
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2, color: 'neutral35' }}>
              We know these exams can be hard sometimes, that's why we have
              unlimited tries!
            </Typography>
            <Box mt={2}>
              <Button
                variant="primary"
                label="Try Again"
                fullWidth={false}
                onClick={() => {
                  reset();
                  setAnswers(assessment.questions.map(question => null));
                }}
              />
            </Box>
            <Box mt={2}>
              <Button
                variant="tertiary"
                label="Close"
                fullWidth={false}
                onClick={() => {
                  dispatch(uiActions.closeActiveModal());
                  reset();
                }}
              />
            </Box>
          </>
        )}
      </DialogContent>
    );
  }

  return (
    <>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'neutral20',
        }}>
        Qualified Assessment
      </DialogTitle>
      <DialogContent
        tabIndex={0}
        sx={theme => ({
          maxWidth: { sm: theme.breakpoints.values.md },
          outlineColor: 'transparent',
        })}>
        <Alert severity="info" sx={{ mt: 2 }}>
          {isCompleted ? (
            <Typography variant="body1">
              This assessment has been completed.
            </Typography>
          ) : (
            <Typography variant="body1">
              In accordance with{' '}
              {assessment.passRate === 70 ? 'NASBA' : 'State Level'} standards,
              participants are required to earn a minimum score of{' '}
              {assessment.passRate}% in order to pass the assessment.
            </Typography>
          )}
        </Alert>
        {isAssessmentLoading ? (
          Array.from({ length: 4 }).map((_, idx) => (
            <Box key={idx} mb={2}>
              <AssessmentQuestionSkeleton />
            </Box>
          ))
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {assessment?.questions.map((question, index) => (
              <FormControl
                key={question.userAssessmentQuestionId}
                sx={{ mt: 4 }}>
                <FormLabel>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: 'text.black', mb: 1 }}>
                    {index + 1}.&nbsp;&nbsp;&nbsp;{question.question}
                  </Typography>
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr 1fr' },
                    gap: 1,
                    mt: 1,
                  }}>
                  {question.answers.map((answer, answerIndex) => (
                    <AnswerBox
                      key={`${index}-${answer.assessmentAnswerId}`}
                      onSelect={value => {
                        setAnswers(R.update(index, value, answers));
                      }}
                      isSelected={
                        isCompleted
                          ? answer.isCorrect
                          : answers[index] === answer.assessmentAnswerId
                      }
                      answer={answer}
                      index={answerIndex}
                      isDisabled={isSubmitLoading || isCompleted}
                      questionId={question.userAssessmentQuestionId}
                    />
                  ))}
                </Box>
              </FormControl>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid', borderColor: 'neutral20' }}>
        <Button
          variant="tertiary"
          label="Close"
          fullWidth={false}
          onClick={() => {
            dispatch(uiActions.closeActiveModal());

            AnalyticsService.logEvent(EVENTS.ASSESSMENT_EVENTS.CLOSE, {
              episodeId,
              assessmentId: assessment.userAssessmentId,
              episodeName: episode?.name,
            });
          }}
        />
        {!isCompleted && (
          <Button
            variant="primary"
            label="Submit"
            fullWidth={false}
            disabled={answers.some(res => R.isNil(res)) || isSubmitLoading}
            onClick={async () => {
              const { data: result } = await submitUserAssessment({
                userAssessmentId: assessment.userAssessmentId,
                episodeId,
                answers,
                antiCheatingActive,
              });

              AnalyticsService.assessmentAttempt({
                episodeId,
                userAssessmentId: assessment.userAssessmentId,
                // for backwards compatibility
                assessmentId: assessment.userAssessmentId,
                episodeName: episode?.name,
                answer: answers,
                score: result?.score,
                pass: result?.pass,
              });

              if (result?.pass) {
                AnalyticsService.episodeComplete({
                  episode,
                  isNasba: true,
                });
              }
            }}
          />
        )}
      </DialogActions>
    </>
  );
};
