import {
  Bookmark,
  BookmarkBorder,
  ConfirmationNumberRounded,
  Feedback,
  ListAlt,
  Lock,
  Share,
} from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/material';

import { Button, ButtonVariants } from '@/components';
import { EpisodeStatus } from '@/constants';
import { PlayArrowFilledIcon, PlayArrowOutlinedIcon } from '@/icons';

const PrimaryButtons = {
  PLAY: 'play',
  REDEEM: 'redeem',
  ASSESSMENT: 'assessment',
  FEEDBACK: 'feedback',
};

export const EpisodeControlButtons = ({
  region,
  isEpisodeRedeemRequired,
  isEpisodePremium,
  episodeStatus,
  onRedeem,
  onPlayNow,
  isBookmarked,
  isBookmarking,
  onToggleBookmark,
  onTrailer,
  onTakeAssessment,
  onGiveFeedback,
  onShare,
}) => {
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  const showPreviewButton = !isMdDown;
  const showAssessmentButton = region === 'USA';
  const showFeedbackButton =
    episodeStatus === EpisodeStatus.VERIFIED && region === 'USA';

  const primaryButton = getPrimaryButton({
    isEpisodeRedeemRequired,
    episodeStatus,
    region,
  });

  const playButtonText = getPlayButtonText({
    episodeStatus,
    isMdDown,
  });

  const FirstButton = () => {
    if (isEpisodePremium) {
      return (
        <Button
          variant={ButtonVariants.PREMIUM}
          label="Premium"
          fullWidth={false}
          skinny={false}
          onClick={onPlayNow}
          startIcon={<Lock />}
        />
      );
    }
    if (isEpisodeRedeemRequired) {
      return (
        <Button
          variant={
            primaryButton === PrimaryButtons.REDEEM
              ? ButtonVariants.PRIMARY
              : ButtonVariants.SECONDARY
          }
          label="Redeem"
          fullWidth={false}
          skinny={false}
          onClick={onRedeem}
          startIcon={<ConfirmationNumberRounded />}
          colorOverride={primaryButton !== PrimaryButtons.REDEEM && 'blue30'}
        />
      );
    }
    return (
      <Button
        variant={
          primaryButton === PrimaryButtons.PLAY
            ? ButtonVariants.PRIMARY
            : ButtonVariants.SECONDARY
        }
        label={playButtonText}
        fullWidth={false}
        skinny={false}
        onClick={onPlayNow}
        startIcon={<PlayArrowFilledIcon />}
        colorOverride={primaryButton !== PrimaryButtons.PLAY && 'blue30'}
      />
    );
  };

  return (
    <Box
      sx={{ display: 'flex', gap: 1, mt: { xs: 0, sm: 2 }, flexWrap: 'wrap' }}>
      <Box sx={{ display: 'flex', gap: 1, mr: 'auto', flexWrap: 'wrap' }}>
        <FirstButton />
        {showPreviewButton && (
          <Button
            variant={ButtonVariants.TERTIARY}
            label="Preview"
            fullWidth={false}
            skinny={false}
            onClick={onTrailer}
            startIcon={<PlayArrowOutlinedIcon />}
            colorOverride="blue30"
          />
        )}
        <Button
          variant={ButtonVariants.TERTIARY}
          fullWidth={false}
          skinny={false}
          onClick={onToggleBookmark}
          disabled={isBookmarking}
          startIcon={isBookmarked ? <Bookmark /> : <BookmarkBorder />}
          colorOverride="blue30"
        />
        <Button
          variant={ButtonVariants.TERTIARY}
          fullWidth={false}
          skinny={false}
          onClick={onShare}
          startIcon={<Share />}
          colorOverride="blue40"
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {showAssessmentButton && (
          <Button
            variant={
              primaryButton === PrimaryButtons.ASSESSMENT
                ? ButtonVariants.PRIMARY
                : ButtonVariants.SECONDARY
            }
            label={
              episodeStatus === EpisodeStatus.VERIFIED
                ? 'View Assessment'
                : 'Take Assessment'
            }
            fullWidth={false}
            skinny={false}
            onClick={onTakeAssessment}
            startIcon={<ListAlt />}
            colorOverride={
              primaryButton !== PrimaryButtons.ASSESSMENT && 'blue30'
            }
          />
        )}
        {showFeedbackButton && (
          <Button
            variant={
              primaryButton === PrimaryButtons.FEEDBACK
                ? ButtonVariants.PRIMARY
                : ButtonVariants.TERTIARY
            }
            label={'Give Feedback'}
            fullWidth={false}
            skinny={false}
            onClick={onGiveFeedback}
            startIcon={<Feedback />}
            colorOverride={
              primaryButton !== PrimaryButtons.FEEDBACK && 'blue30'
            }
          />
        )}
      </Box>
    </Box>
  );
};

const getPrimaryButton = ({
  isEpisodeRedeemRequired,
  episodeStatus,
  region,
}) => {
  if (isEpisodeRedeemRequired) return PrimaryButtons.REDEEM;
  if (episodeStatus === EpisodeStatus.VERIFIED && region === 'USA')
    return PrimaryButtons.FEEDBACK;
  if (episodeStatus === EpisodeStatus.ASSESSMENT_READY)
    return PrimaryButtons.ASSESSMENT;
  return PrimaryButtons.PLAY;
};

const getPlayButtonText = ({ episodeStatus, isMdDown }) => {
  if (
    episodeStatus === EpisodeStatus.VERIFIED ||
    episodeStatus === EpisodeStatus.COMPLETED
  )
    return isMdDown ? 'Play' : 'Play Again';
  if (!episodeStatus || episodeStatus === EpisodeStatus.NONE)
    return isMdDown ? 'Play' : 'Play Now';
  return 'Continue';
};
