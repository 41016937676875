import { Box, Hidden, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, NASBADisclaimerModal } from '@/components';
import { LUMIQ_ORGANIZATION_NAME, Routes } from '@/constants';
import { LumiQCombinationMarkIcon } from '@/icons';
import { certificationCan, certificationUsa, mobileDevices } from '@/images';
import { useGetInviteDetailsQuery } from '@/store';

export const AuthInfo = ({ inputRef }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showNASBAModal, setShowNASBAModal] = useState(false);

  const onLoginClick = () => {
    if (location.pathname !== Routes.LOGIN) {
      navigate({
        pathname: Routes.LOGIN,
        search: location.search,
      });
    }
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onRegisterClick = () => {
    if (location.pathname !== Routes.REGISTER) {
      navigate({
        pathname: Routes.REGISTER,
        search: location.search,
      });
    }
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { inviteToken } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  const { data, isLoading: isInviteDetailsLoading } = useGetInviteDetailsQuery(
    { inviteToken },
    { skip: !inviteToken },
  );

  const bodyText = getBodyText({
    pathname: location.pathname,
    organizationName: data?.organizationName,
    inviteToken,
  });

  return (
    <Box sx={[styles.pageSection, styles.gradientBackground]}>
      <LumiQCombinationMarkIcon
        sx={{ width: 150, height: 39, color: 'white' }}
      />
      <Typography variant="h1" sx={{ color: 'white', mt: 4, mb: 2 }}>
        Welcome to LumiQ
      </Typography>
      {isInviteDetailsLoading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" sx={{ width: '70%' }} />
        </>
      ) : (
        <Typography variant="h3" sx={{ color: 'white' }}>
          {bodyText}
        </Typography>
      )}
      <Hidden mdUp>
        <Button
          variant="gradientPrimary"
          label="Log In"
          sx={{ mt: 2 }}
          skinny={false}
          onClick={onLoginClick}
        />
        <Button
          variant="gradientPrimary"
          label="Sign Up"
          sx={{ mt: 2 }}
          skinny={false}
          onClick={onRegisterClick}
        />
      </Hidden>
      <Box
        component="img"
        src={mobileDevices}
        alt="mobile devices"
        sx={{ width: '100%', maxWidth: 500, display: 'block', mx: 'auto' }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
        <Box
          component="img"
          src={certificationCan}
          alt="canadian certification"
          sx={{ height: 100, p: 2 }}
        />
        <Box
          component="img"
          src={certificationUsa}
          alt="usa certification"
          onClick={() => setShowNASBAModal(true)}
          sx={styles.nasbaDisclaimerIcon}
        />
      </Box>
      <NASBADisclaimerModal
        isOpen={showNASBAModal}
        onClose={() => setShowNASBAModal(false)}
      />
    </Box>
  );
};

const getBodyText = ({ pathname, organizationName, inviteToken }) => {
  if (pathname === Routes.LOGIN) {
    return "We're on a mission to make professional education enjoyable.";
  }
  const isOrgInvite =
    !!inviteToken && organizationName !== LUMIQ_ORGANIZATION_NAME;
  if (!isOrgInvite) {
    return 'LumiQ is an on-the-go podcast platform built for finance professionals. Earning professional education on your schedule.';
  }
  return `${organizationName || 'Your company'} has provided you with a subscription to LumiQ, an on-the-go podcast platform built for finance professionals.`;
};

const styles = {
  pageSection: {
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: 4,
      sm: 8,
    },
    flex: 1,
  },
  gradientBackground: {
    background: theme => theme.gradients.primary,
  },
  nasbaDisclaimerIcon: {
    height: 100,
    p: 2,
    borderRadius: 2,
    cursor: 'pointer',
    '&:hover': { backgroundColor: theme => `${theme.palette.blueBlack}33` },
  },
};
