import { CloudDownload } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { Button, ButtonProgressSpinner } from '@/components';
import { TrackerMessages } from '@/constants';
import { useDownloadCertificateSummaryMutation } from '@/store';
import { SnackbarUtils } from '@/utils';

import {
  formatCPDDateRangeLabel,
  formatTriennialDateRangeLabel,
} from '../utils';
import { BarChart } from './BarChart';
import { SummaryCredits } from './SummaryCredit';

export const Summary = ({ summary, province, yearlyRequirement }) => {
  const [downloadCertificateSummary, { isLoading: isSummaryDownloading }] =
    useDownloadCertificateSummaryMutation();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { completed, ethics } = summary.yearlySummary.reduce(
    (totals, summary) => ({
      completed: totals.completed + summary.completed,
      ethics: totals.ethics + summary.ethics,
    }),
    { completed: 0, ethics: 0 },
  );

  const { fromDate, toDate } = formatTriennialDateRangeLabel({
    province,
    yearlySummary: summary.yearlySummary,
  });

  const onDownloadSummaryClick = async () => {
    if (isSummaryDownloading) return;
    const { error } = await downloadCertificateSummary({
      fromDate: fromDate.value,
      toDate: toDate.value,
    });
    if (error) SnackbarUtils.error(TrackerMessages.DOWNLOAD_ERROR);
  };

  return (
    <Box sx={styles.root}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12}>
          <Box sx={styles.topContainer}>
            <Box>
              <Typography variant="h2">Rolling Year</Typography>
              <Typography variant="select" sx={{ color: 'neutral35' }}>
                From {fromDate.label} to {toDate.label}
              </Typography>
            </Box>
            {isMobile ? (
              <IconButton
                disabled={!completed || isSummaryDownloading}
                onClick={onDownloadSummaryClick}>
                {isSummaryDownloading ? (
                  <ButtonProgressSpinner />
                ) : (
                  <CloudDownload sx={{ color: 'blueBlack', fontSize: 20 }} />
                )}
              </IconButton>
            ) : (
              <Button
                disabled={!completed || isSummaryDownloading}
                onClick={onDownloadSummaryClick}
                startIcon={
                  isSummaryDownloading ? (
                    <ButtonProgressSpinner />
                  ) : (
                    <CloudDownload />
                  )
                }
                label="Download CPD Summary"
                variant="secondary"
                fullWidth={false}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={styles.barChartContainer}>
            {summary.yearlySummary.map(summary => (
              <BarChart
                key={summary.year}
                label={formatCPDDateRangeLabel({
                  year: summary.year,
                  province,
                })}
                yearlyRequirement={yearlyRequirement}
                totalCredits={summary.completed}
                ethicsCredits={summary.ethics}
                lumiqCredits={summary.lumiqHours}
                lumiqEthicsCredits={summary.lumiqEthicsHours}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SummaryCredits
                credits={completed}
                label="Rolling Year CPD"
                requirement={summary.triennialRequirement}
              />
            </Grid>
            <Grid item xs={12}>
              <SummaryCredits
                credits={ethics}
                label="3-Year Ethic Hours"
                requirement={summary.triennialEthicsRequirement}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  root: {
    border: theme => `solid 1px ${theme.palette.neutral10}`,
    borderRadius: 2,
    boxShadow: theme => `0 2px 5px 0 ${theme.palette.neutral10}`,
    boxSizing: 'border-box',
    p: 3,
  },
  topContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  barChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
};
