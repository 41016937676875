import { useSelector } from 'react-redux';

import { EpisodeCardCarousel } from '@/components';
import {
  selectSessionUserId,
  selectUserRegion,
  useFetchRecommendedEpisodesQuery,
} from '@/store';

export const RecommendedEpisodesCarousel = () => {
  const region = useSelector(selectUserRegion);
  const userId = useSelector(selectSessionUserId);
  const { data: episodes, isLoading } = useFetchRecommendedEpisodesQuery(
    region,
    { skip: !userId },
  );

  return (
    <>
      {episodes && episodes?.length > 0 && (
        <EpisodeCardCarousel
          title={`Recommended for You`}
          uKey="Recommended for you"
          episodes={episodes}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
