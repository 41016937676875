import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { Routes } from '@/constants';
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  SubscriptionBanner,
} from '@/containers';
import { useAccessInfo } from '@/hooks';
import { selectSessionUserId } from '@/store';

export const APP_LAYOUT_PX = { xs: 3, sm: 6 };

export const AppLayout = ({ children }) => {
  const userId = useSelector(selectSessionUserId);
  const { pathname } = useLocation();
  const containerRef = useRef();

  const navbarAccessTypeInfo = useAccessInfo();
  const spacingMultiplier = navbarAccessTypeInfo?.showUpgradeBanner ? 2 : 1;

  useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, [pathname]);

  return (
    <Box
      ref={containerRef}
      sx={({ dimensions }) => ({
        minHeight: '100vh',
        width: '100%',
        pt: {
          md: `${dimensions.header.height + (userId ? 0 : dimensions.regionBanner.height)}px`,
          xs: `${dimensions.header.height * spacingMultiplier + (userId ? 0 : dimensions.regionBanner.height)}px`,
        },
        pl: { xs: 0, lg: dimensions.sidebar.width + 'px' },
      })}>
      <AppHeader />
      <SubscriptionBanner />
      <AppSidebar />
      <Box sx={styles.contentContainer}>{children}</Box>
      <AppFooter />
    </Box>
  );
};

const styles = {
  contentContainer: {
    width: '100%',
    overflow: 'hidden',
    py: 3,
    px: APP_LAYOUT_PX,
  },
};
