import { Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EpisodeGrid } from '@/components';
import { EVENT_CONSTANTS } from '@/services';
import {
  selectUserRegion,
  useFetchEpisodesQuery,
  useFetchRelatedEpisodesQuery,
} from '@/store';

export const RelatedEpisodes = ({ episode, allowSingleColumn, referrer }) => {
  const region = useSelector(selectUserRegion);

  const { data: relatedEpisodesV2 } = useFetchRelatedEpisodesQuery({
    episodeId: episode.episodeId,
    region,
  });

  const relatedEpisodeIds = useMemo(
    () =>
      R.pipe(
        R.uniq,
        R.slice(0, 6),
      )([
        ...(relatedEpisodesV2?.map(e => e.episodeId) || []),
        ...(episode.relatedEpisodes || []),
      ]),
    [episode.relatedEpisodes, relatedEpisodesV2],
  );

  const { data: relatedEpisodes } = useFetchEpisodesQuery(
    {
      episodeIds: relatedEpisodeIds,
      region,
    },
    {
      skip: !relatedEpisodeIds?.length,
    },
  );

  if (!relatedEpisodes?.length) return null;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Related Episodes
      </Typography>
      <EpisodeGrid
        episodeIds={relatedEpisodes.map(e => e.episodeId)}
        allowSingleColumn={allowSingleColumn}
        referrer={{
          ...referrer,
          component: EVENT_CONSTANTS.COMPONENT.RELATED_EPISODE,
          episodeId: episode.episodeId,
          episodeName: episode.name,
        }}
      />
    </Box>
  );
};
