import {
  Check,
  ConfirmationNumber,
  Edit,
  OndemandVideo,
  PauseCircleFilled,
  PlayCircleFilled,
} from '@mui/icons-material';

import { ChapterStatus } from '@/constants';

export const ChapterTileIcon = ({
  index,
  chapterStatus,
  chapterType,
  isPrimaryChapter,
  isChapterPlaying,
  isChapterVerifiable,
  isEpisodeRedeemRequired,
  isIconHovered,
}) => {
  const opacity = isPrimaryChapter ? 1 : 0.6;

  const PlayIcon = chapterType === 'video' ? OndemandVideo : PlayCircleFilled;

  if (isEpisodeRedeemRequired && isChapterVerifiable && index > 0)
    return <ConfirmationNumber sx={{ color: 'blue30', opacity }} />;

  if (chapterStatus === ChapterStatus.QUIZ_READY)
    return <Edit sx={{ color: 'blue30' }} />;

  if (chapterStatus === ChapterStatus.VERIFIED) {
    if (isChapterPlaying) return <PauseCircleFilled sx={{ color: 'teal30' }} />;
    if (isIconHovered) return <PlayIcon sx={{ color: 'teal30' }} />;
    return <Check sx={{ color: 'teal30' }} />;
  }

  if (isChapterPlaying) return <PauseCircleFilled sx={{ color: 'blue30' }} />;

  return <PlayIcon sx={{ color: 'blue30', opacity }} />;
};
