import { Box, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CardInputElement } from '@/components';
import {
  postgrestApi,
  selectSessionUserId,
  useAddPaymentMethodMutation,
  useFetchUserProfileQuery,
  useGetPaymentActionResultQuery,
  useGetPaymentMethodsQuery,
} from '@/store';
import { PostgrestApiTags } from '@/store/store.constants';
import { SnackbarUtils } from '@/utils';

export const UpdatePaymentInfo = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const { data: user } = useFetchUserProfileQuery(
    userId ? undefined : skipToken,
  );
  const { data: paymentMethod } = useGetPaymentMethodsQuery();
  const [addPaymentMethod, { data: updatePaymentResponse, reset }] =
    useAddPaymentMethodMutation();
  const { data: paymentActionResult } = useGetPaymentActionResultQuery(
    { asyncResultId: updatePaymentResponse?.asyncResultId },
    { skip: !updatePaymentResponse?.asyncResultId, pollingInterval: 1000 },
  );
  const [timer, setTimer] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (
      paymentActionResult?.isComplete &&
      updatePaymentResponse?.asyncResultId
    ) {
      reset();
      clearTimeout(timer);
      dispatch(
        postgrestApi.util.invalidateTags([PostgrestApiTags.PAYMENT_METHODS]),
      );
      setIsEditing(false);
      setIsSubmitting(false);

      if (paymentActionResult?.error) {
        SnackbarUtils.error(
          'Credit card information could not be updated. Please try again later.',
        );
      } else {
        SnackbarUtils.success('Payment method added successfully');
      }
    }
  }, [paymentActionResult, reset, updatePaymentResponse, timer, dispatch]);

  const elements = useElements();
  const stripe = useStripe();

  const submit = async () => {
    try {
      setIsSubmitting(true);
      const card = elements.getElement(CardElement);
      const name = `${user.firstName} ${user.lastName}`;

      const { token, error } = await stripe.createToken(card, { name });
      if (error) throw error;
      if (!token) throw new Error('Failed to create token');

      await addPaymentMethod({ token: token.id }).unwrap();
      const timeout = setTimeout(() => {
        reset();
        SnackbarUtils.error('Failed to add payment method');
      }, 30000);
      setTimer(timeout);
    } catch (err) {
      SnackbarUtils.error(err?.message || 'Failed to add payment method');
      setIsEditing(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3">Payment Info</Typography>
      </Grid>
      <Grid item xs={12}>
        {isEditing ? (
          <CardInputElement disabled={isSubmitting} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 1,
              backgroundColor: 'neutral10',
              border: theme => `1px solid ${theme.palette.neutral20tint3}`,
              color: 'neutral35',
              minHeight: 52,
              padding: 2,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}>
            <Typography>
              {`**** **** **** ${paymentMethod?.last4 || '****'}`}
            </Typography>
            <Typography>
              {`${
                paymentMethod?.expMonth?.toString?.().padStart(2, '0') || '**'
              } / ${paymentMethod?.expYear || '****'}`}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Grid container spacing={4} justifyContent="flex-end">
          <Grid item xs={6} md={3}>
            <Button
              disabled={!isEditing || isSubmitting}
              variant="tertiary"
              onClick={() => {
                setIsEditing(false);
                setIsSubmitting(false);
              }}
              label="Cancel"></Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              onClick={() => {
                if (isEditing) {
                  submit();
                } else {
                  setIsEditing(true);
                }
              }}
              label={isEditing ? 'Save' : 'Edit'}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
