import { Box, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { Pill } from '@/components';
import { Routes } from '@/constants';
import { AnalyticsService, EVENTS } from '@/services';
import { createRouteWithParams } from '@/utils';

const ROW_MIN_HEIGHT = 145;

export const EpisodeRowMobile = ({ episode, referrer }) => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `${ROW_MIN_HEIGHT * 3}px 0px ${ROW_MIN_HEIGHT * 3}px 0px`,
    triggerOnce: true,
    onChange: inView => {
      if (inView) {
        AnalyticsService.episodeImpression({
          episode,
          location: { component: 'search row' },
          referrer: {
            page: 'technical search',
            ...referrer,
          },
        });
      }
    },
  });

  const onEpisodeClick = () => {
    AnalyticsService.logEvent(EVENTS.TECHNICAL_SEARCH_EVENTS.VIEW_EPISODE, {
      episodeId: episode.episodeId,
      episodeName: episode.name,
    });
    navigate(
      {
        pathname: createRouteWithParams(Routes.EPISODE, {
          episodeId: episode.episodeId,
        }),
      },
      {
        state: {
          referrer: { page: 'technical search', component: 'row' },
        },
      },
    );
  };

  return (
    <Box sx={styles.episodeRowContainer} onClick={onEpisodeClick} ref={ref}>
      {inView && (
        <>
          <Box sx={styles.coverContainer}>
            <img src={`${episode.cover}?width=120`} alt="episode cover" />
          </Box>
          <Box sx={styles.textContainer}>
            <Typography sx={styles.titleText}>{episode.name}</Typography>
            <Typography sx={styles.categoryText}>
              {episode.categories}
            </Typography>
            <Box sx={styles.tagPillContainer}>
              {episode.tags
                .filter(tag => !!tag.subType)
                .map((tag, index) => (
                  <Box sx={styles.tagPill}>
                    <Pill key={index} label={tag.subType} size="small" />
                  </Box>
                ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const styles = {
  episodeRowContainer: {
    display: 'flex',
    p: 1.25,
    alignItems: 'flex-start',
    borderRadius: 1.5,
    cursor: 'pointer',
    minHeight: ROW_MIN_HEIGHT,
    '&:hover': {
      backgroundColor: 'blue10tint2',
    },
  },
  coverContainer: {
    flexShrink: 0,
    height: 125,
    width: 98,
    borderRadius: 1.5,
    overflow: 'hidden',
    mr: 2,
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleText: theme => ({
    ...theme.typography.subtitle2,
  }),
  categoryText: theme => ({
    ...theme.typography.subtitle2,
    pt: 0.5,
  }),
  tagPillContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 0.75,
  },
  tagPill: {
    mr: 0.5,
    mb: 0.5,
  },
};
