import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Box } from '@mui/material';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Markdown } from '@/components';
import { selectUserRegion, useFetchRelatedEpisodesQuery } from '@/store';
import { theme } from '@/theme';

import { Resources } from '../../Resources';
import { CPESummary } from './CPESummary';
import { RelatedEpisodes } from './RelatedEpisodes';
import { Speaker } from './Speaker';

export const EpisodeDescription = ({ episode, referrer }) => {
  const region = useSelector(selectUserRegion);
  const { speakers, resources, CPEDetails, relatedEpisodes } = episode;
  const isRecommendedEpisodesEnabled = useFeatureIsOn(
    'episode-recommendations',
  );

  const { data: relatedEpisodesV2 } = useFetchRelatedEpisodesQuery(
    {
      episodeId: episode.episodeId,
      region,
    },
    {
      skip: !isRecommendedEpisodesEnabled,
    },
  );

  const combinedRelatedEpisodes = useMemo(
    () =>
      R.pipe(
        R.uniq,
        R.slice(0, 6),
      )([
        ...(relatedEpisodesV2?.map(e => e.episodeId) || []),
        ...(relatedEpisodes || []),
      ]),
    [relatedEpisodes, relatedEpisodesV2],
  );

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
        }}>
        {speakers.map(speaker => (
          <Speaker key={speaker.name} speaker={speaker} />
        ))}
      </Box>
      <Markdown sx={theme.typography.body2}>{episode.description}</Markdown>
      {episode.resources?.length > 0 && (
        <Resources resources={resources} episode={episode} />
      )}
      {region === 'USA' && CPEDetails && <CPESummary CPEDetails={CPEDetails} />}
      {combinedRelatedEpisodes?.length > 0 && (
        <RelatedEpisodes
          relatedEpisodes={combinedRelatedEpisodes}
          referrer={{
            ...referrer,
            episodeId: episode.episodeId,
            episodeName: episode.name,
          }}
        />
      )}
    </>
  );
};
