import {
  AssignmentRounded,
  ConfirmationNumberRounded,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components';
import { Modals } from '@/constants';
import {
  selectIsEpisodeRedeemRequired,
  selectUserRegion,
  uiActions,
  userEpisodesThunks,
} from '@/store';

export const Assessment = ({ episodeId }) => {
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const region = useSelector(selectUserRegion);
  const dispatch = useDispatch();

  if (region === 'CAN') return null;

  return isEpisodeRedeemRequired ? (
    <Button
      label="Take Assessment"
      variant="gradientPrimary"
      colorOverride="white"
      skinny={false}
      startIcon={<ConfirmationNumberRounded />}
      onClick={() => {
        dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
      }}
    />
  ) : (
    <Button
      variant="gradientPrimary"
      skinny={false}
      label="View Assessment"
      startIcon={<AssignmentRounded />}
      onClick={() => {
        dispatch(
          uiActions.setActiveModal({
            name: Modals.ASSESSMENT,
            params: { episodeId },
          }),
        );
      }}
    />
  );
};
