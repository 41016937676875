import { Alert, Typography } from '@mui/material';

export const WarningBanners = ({ episode, isEpisodeNotNasba = false }) => {
  return (
    <>
      {episode.isArchived && (
        <Alert severity="warning" sx={{ my: 2 }}>
          <Typography variant="select">
            This episode has been archived
          </Typography>
        </Alert>
      )}
      {isEpisodeNotNasba && (
        <Alert severity="warning" sx={{ my: 2 }}>
          <Typography variant="select">
            This episode is NOT NASBA QAS Self-Study Sponsor Qualified
          </Typography>
        </Alert>
      )}
    </>
  );
};
