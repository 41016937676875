import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material';

type SkeletonProps = MuiSkeletonProps & {
  width?: number | string;
  height?: number | string;
};

export const Skeleton = ({
  variant = 'text',
  width = '100%',
  height = '100%',
  animation = 'wave',
  sx,
  ...rest
}: SkeletonProps) => (
  <MuiSkeleton
    animation={animation}
    sx={{ bgcolor: 'neutral10tint1', width, height, ...sx }}
    variant={variant}
    {...rest}
  />
);
