import {
  Bookmark,
  BookmarkBorder,
  MoreVert,
  Pause,
  PlayArrow,
  Share,
} from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBookmarkManagement } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectEpisode,
  selectSessionUserId,
  useShowProductFeedbackIfReadyMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { selectIsEpisodePlaying } from '../../selectors';

export const EpisodeDropdownMenu = ({ episodeId, referrer }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const userId = useSelector(selectSessionUserId);
  const episode = useSelector(state => selectEpisode(state, episodeId));
  const activeChapterId = useSelector(selectActiveChapterId);
  const isEpisodePlaying = useSelector(state =>
    selectIsEpisodePlaying(state, episodeId),
  );

  const location = {
    ...referrer,
    component: EVENT_CONSTANTS.COMPONENT.THREE_DOT_MENU,
  };

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  const isTrailerActive = activeChapterId === episode.trailerId;

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onPlayClick = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId,
        location: {
          ...location,
          button: EVENT_CONSTANTS.BUTTON.PLAY,
        },
        referrer,
      }),
    );
  };

  const { toggleBookmark, isBookmarked, isBookmarking } = useBookmarkManagement(
    {
      episodeId,
      location: { ...location, button: EVENT_CONSTANTS.BUTTON.BOOKMARK },
      referrer,
    },
  );

  const onShareClick = () => {
    navigator.clipboard.writeText(episode?.deepLink);
    AnalyticsService.episodeShare({
      episode,
      location: { ...location, button: EVENT_CONSTANTS.BUTTON.SHARE },
    });
    SnackbarUtils.success('Link copied to clipboard');
    if (userId) showProductFeedbackIfReady();
  };

  return (
    <Box>
      <IconButton
        sx={{ color: 'inherit', mr: 0.5 }}
        aria-label="more"
        aria-controls="episode-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        classes={{ paper: { width: 'fit-content' } }}
        sx={{ '& .MuiSvgIcon-root': { mr: 1 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <MenuItem onClick={onPlayClick} disabled={episode.isArchived}>
          {isEpisodePlaying && !isTrailerActive ? (
            <>
              <Pause />
              <Typography variant="subtitle2">Pause</Typography>
            </>
          ) : (
            <>
              <PlayArrow />
              <Typography variant="subtitle2">Play</Typography>
            </>
          )}
        </MenuItem>
        <MenuItem
          onClick={toggleBookmark}
          disabled={isBookmarking || episode.isArchived}>
          {isBookmarked ? (
            <>
              <Bookmark />
              <Typography variant="subtitle2">Remove Bookmark</Typography>
            </>
          ) : (
            <>
              <BookmarkBorder />
              <Typography variant="subtitle2">Bookmark</Typography>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={onShareClick} disabled={episode.isArchived}>
          <Share />
          <Typography variant="subtitle2">Share</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
