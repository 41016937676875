import { Box, Typography } from '@mui/material';

import { AssessmentAnswer, QuizAnswer } from '@/types';

import { Skeleton } from '../Skeleton';

interface AnswerBoxProps {
  answer: QuizAnswer | AssessmentAnswer;
  onSelect: (value: string | number) => void;
  isSelected: boolean;
  index: number;
  isPending?: boolean;
  isDisabled?: boolean;
  questionId: string;
}

export const AnswerBox = ({
  onSelect,
  answer,
  isSelected,
  index,
  isPending,
  isDisabled,
  questionId,
}: AnswerBoxProps) => {
  const isTestMode = localStorage.getItem('testMode') === 'true';
  const value =
    (answer as QuizAnswer).id ||
    (answer as AssessmentAnswer).assessmentAnswerId;

  return (
    <Box
      component="label"
      sx={[
        styles.answerContainer,
        isTestMode && answer.isCorrect && styles.answerCorrect,
        isSelected && styles.answerSelected,
        !isSelected && !isDisabled && styles.answerClickable,
      ]}>
      {isPending ? (
        <Skeleton animation={false} />
      ) : (
        <Typography variant="body1">
          {(index + 10).toString(36).toUpperCase()})&nbsp;
          {'answerLabel' in answer ? answer.answerLabel : answer.answer}
          <input
            style={{ opacity: 0, pointerEvents: 'none', position: 'fixed' }}
            type="radio"
            name={questionId}
            value={value}
            disabled={isDisabled}
            checked={isSelected}
            onChange={() => {
              onSelect(value);
            }}
          />
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  answerContainer: {
    display: 'block',
    border: '1px solid',
    borderColor: 'blueBlack',
    borderRadius: 1,
    p: 2,
    minHeight: 70,
    height: '100%',
    backgroundColor: 'white',
    color: 'neutral35',
    alignContent: 'center',
    '&:focus-within': {
      outline: 'blue30 solid 2px',
    },
  },
  answerClickable: {
    cursor: 'pointer',
  },
  answerSelected: {
    backgroundColor: 'blue30',
    color: 'white',
  },
  answerCorrect: {
    backgroundColor: 'greenLight',
  },
};
