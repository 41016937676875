import { Fragment } from 'react';

import {
  AppDownloadModal,
  AssessmentModal,
  BrowserAutoPlayModal,
  ChapterQuizModal,
  EpisodeEvaluationModal,
  EpisodeFeedbackModal,
  EthicsLockedModal,
  InvalidPlanModal,
  OnboardingModal,
  PlanUsedUpModal,
  PremiumLockedModal,
  PurchaseModal,
  RedeemModal,
  SignupModal,
  TrackerAddEditModal,
  TrackerDeleteModal,
} from './components';
import { ProductFeedbackModal } from './components/ProductFeedbackModal';

export const AppModals = () => {
  return (
    <Fragment>
      <OnboardingModal />
      <BrowserAutoPlayModal />
      <RedeemModal />
      <AssessmentModal />
      <ChapterQuizModal />
      <TrackerAddEditModal />
      <TrackerDeleteModal />
      <SignupModal />
      <EthicsLockedModal />
      <PremiumLockedModal />
      <InvalidPlanModal />
      <PlanUsedUpModal />
      <EpisodeEvaluationModal />
      <EpisodeFeedbackModal />
      <ProductFeedbackModal />
      <PurchaseModal />
      <AppDownloadModal />
    </Fragment>
  );
};
