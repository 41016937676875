import { useDispatch, useSelector } from 'react-redux';

import { Modals } from '@/constants';
import { useBookmarkManagement } from '@/hooks';
import { AnalyticsService, EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectIsEpisodeRedeemRequired,
  selectIsFreeTrialUser,
  selectSessionUserId,
  selectUserRegion,
  uiActions,
  userEpisodesSelectors,
  userEpisodesThunks,
  useShowProductFeedbackIfReadyMutation,
} from '@/store';
import { SnackbarUtils } from '@/utils';

import { EpisodeControlButtons as EpisodeControlButtonsComponent } from './EpisodeControlButtons.component';

export const EpisodeControlButtons = ({ episode, referrer }) => {
  const { episodeId } = episode;

  const dispatch = useDispatch();

  const userId = useSelector(selectSessionUserId);
  const userRegion = useSelector(selectUserRegion);
  const isFreeTrialUser = useSelector(selectIsFreeTrialUser);
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, episodeId),
  );
  const userEpisode = useSelector(state =>
    userEpisodesSelectors.selectById(state, episodeId),
  );
  const episodeStatus = userEpisode?.status;

  const [showProductFeedbackIfReady] = useShowProductFeedbackIfReadyMutation();

  const {
    toggleBookmark: onToggleBookmark,
    isBookmarking,
    isBookmarked,
  } = useBookmarkManagement({
    episodeId,
    location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.BOOKMARK },
    referrer,
  });

  if (episode.isArchived || (userRegion === 'USA' && episode.region !== 'USA'))
    return null;

  const onRedeem = () => {
    dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
  };

  const onPlayNow = () => {
    dispatch(
      playerThunks.episodePlayPressed({
        episodeId,
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PLAY_NOW },
        referrer,
      }),
    );
  };

  const onTrailer = () => {
    dispatch(
      playerThunks.chapterPlayPressed({
        activeChapter: {
          episodeId,
          chapterId: episode.trailerId,
        },
        location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.PREVIEW },
        referrer,
      }),
    );
  };

  const onTakeAssessment = () => {
    if (isEpisodeRedeemRequired) {
      dispatch(userEpisodesThunks.initiateRedemption({ episodeId }));
      return;
    }
    dispatch(
      uiActions.setActiveModal({
        name: Modals.ASSESSMENT,
        params: { episodeId },
      }),
    );
  };

  const onGiveFeedback = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.EPISODE_EVALUATION,
        params: { episodeId },
      }),
    );
  };

  const onShare = () => {
    navigator.clipboard.writeText(episode.deepLink);
    AnalyticsService.episodeShare({
      episode,
      location: { ...referrer, button: EVENT_CONSTANTS.BUTTON.SHARE },
    });
    SnackbarUtils.success('Link copied to clipboard');
    if (userId) showProductFeedbackIfReady();
  };

  const episodeControlButtonsProps = {
    region: userRegion,
    isEpisodeRedeemRequired,
    isEpisodePremium: isFreeTrialUser && episode.isPremium,
    episodeStatus,
    onRedeem,
    onPlayNow,
    isBookmarked,
    isBookmarking,
    onToggleBookmark,
    onTrailer,
    onTakeAssessment,
    onGiveFeedback,
    onShare,
  };

  return <EpisodeControlButtonsComponent {...episodeControlButtonsProps} />;
};
