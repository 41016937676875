import { Modals } from '@/constants';

import { AppModal } from '../AppModal';
import { ChapterQuizModalContent } from './ChapterQuizModalContent.container';

export const ChapterQuizModal = () => {
  return (
    <AppModal
      modalName={Modals.CHAPTER_QUIZ}
      scroll="paper"
      maxWidth={false}
      sx={{
        '*': {
          msOverflowStyle: 'initial',
          scrollbarWidth: 'initial',
        },
        '*::-webkit-scrollbar': {
          display: 'block',
          width: 5,
          height: 8,
          backgroundColor: 'neutral10tint1',
          borderRadius: 5,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'neutral30',
          borderRadius: 5,
        },
      }}>
      <ChapterQuizModalContent />
    </AppModal>
  );
};
