import { Box } from '@mui/material';
import MarkdownComponent from 'react-markdown';

export const Markdown = ({ sx, children }) => {
  return (
    <Box
      sx={{
        ...sx,
        '& a': {
          color: 'blue30',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}>
      <MarkdownComponent>{children}</MarkdownComponent>
    </Box>
  );
};
