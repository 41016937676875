import { Box } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';

import { theme } from '@/theme';

export const CardInputElement = ({ disabled }) => {
  return (
    <Box
      sx={{
        '.card-input-element': {
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '4px',
          padding: '18px 12px',
          width: '100%',
        },
      }}>
      <CardElement
        options={{
          disableLink: true,
          disabled,
          style: {
            base: {
              color: theme.palette.blueBlack,
              fontFamily: theme.typography.fontFamily,
              fontSize: '16px',
              fontWeight: 400,
            },
            invalid: {
              color: theme.palette.red,
              iconColor: theme.palette.red,
            },
          },
        }}
        className="card-input-element"
      />
    </Box>
  );
};
