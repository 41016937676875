import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { canFlag, usaFlag } from '@/images';
import { selectSessionUserId, selectUserRegion, updateRegion } from '@/store';

const USER_REGION_COPY = {
  CAN: 'Canadian CPA',
  USA: 'USA CPA',
};

const USER_REGION_FLAG = {
  CAN: canFlag,
  USA: usaFlag,
};

export const RegionBanner = () => {
  const dispatch = useDispatch();
  const userRegion = useSelector(selectUserRegion);
  const userId = useSelector(selectSessionUserId);

  const switchRegion = () => {
    const region = userRegion === 'CAN' ? 'USA' : 'CAN';
    dispatch(updateRegion({ region }));
  };

  if (userId) return null;

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.header}>
        Not a {USER_REGION_COPY[userRegion]}? Switch Your Country Here&nbsp;
      </Typography>

      <Box
        component="img"
        alt={userRegion + ' flag'}
        src={USER_REGION_FLAG[userRegion]}
        sx={styles.flagImage}
        onClick={switchRegion}
      />
    </Box>
  );
};

const styles = {
  root: {
    bgcolor: 'blue10',
    color: 'blue50',
    height: ({ dimensions }) => dimensions.regionBanner.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: theme => ({
    ...theme.typography.caption,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.overline,
    },
  }),
  flagImage: {
    maxWidth: 27,
    mr: 0.5,
    cursor: 'pointer',
    ml: 0.5,
  },
};
