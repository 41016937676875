import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { FsmChapterStatus } from '@/constants';
import {
  answerQuiz,
  fetchPlayerConfig,
  fetchPlayerSessions,
  fetchSession,
  pausePlayerSession,
  PauseSessionParams,
  playPlayerSession,
  PlaySessionParams,
  PostSeekPositionParams,
  PostSetPositionParams,
  resumeLastPlayerSession,
  seekPosition,
  setPosition,
  startPlayerSession,
} from '@/requests';
import { UserEpisode, UserEpisodeFsm } from '@/types';

import {
  AnswerQuizPayload,
  ConfigResponse,
  FindPlayerSessionByEpisodeIdPayload,
  FindPlayerSessionByEpisodeIdResponse,
  PlayerSessionTransitionResponse,
  ResumeLastSessionPayload,
  ResumeLastSessionResponse,
  StartSessionPayload,
  StartSessionResponse,
} from '../player-fsm/player-fsm.types';
import { postgrestApi } from '../postgrest-api';
import { PostgrestApiTags } from '../store.constants';
import { handleOnQueryStartedError } from '../store.utils';
import {
  transformPlayerTransitionResponse,
  transformSessionResponse,
  transformSessionResponseNullable,
  transformSessionsResponse,
} from './player-fsm-api.utils';

export const playerSessionsAdapter = createEntityAdapter<UserEpisode, string>({
  selectId: entity => entity.episodeId,
});

export const playerFsmApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    fetchPlayerConfig: builder.query<ConfigResponse, void>({
      query: fetchPlayerConfig,
      transformResponse: (response: any) => {
        return {
          CAN: response.cpd,
          USA: response.cpe,
        };
      },
      providesTags: [PostgrestApiTags.PLAYER_CONFIG],
    }),
    fetchPlayerSessions: builder.query<
      EntityState<UserEpisode, string>,
      undefined
    >({
      query: fetchPlayerSessions,
      transformResponse: (response: any[]) => {
        const userEpisodes = transformSessionsResponse(response);
        return playerSessionsAdapter.setAll(
          playerSessionsAdapter.getInitialState(),
          userEpisodes,
        );
      },
      providesTags: [PostgrestApiTags.PLAYER_SESSIONS],
    }),
    /*
      TODO: fetchSessionQuizzes doesn't actually need to be a separate query
      from fetchPlayerSessions, just a different transform.
      Merge them once we don't need to support the old non-session queries.
    */
    fetchSessionQuizzes: builder.query<any, any>({
      query: fetchPlayerSessions,
      transformResponse: (response: any) => {
        const quizReadyEpisodeIdsAndQuizCounts = response
          .filter((e: any) =>
            e.chapter_states.some(
              (state: string) => state === FsmChapterStatus.QUIZ_READY,
            ),
          )
          .map((e: any) => ({
            episodeId: e.episode_id,
            availableQuizCount: e.chapter_states.filter(
              (state: string) => state === FsmChapterStatus.QUIZ_READY,
            ).length,
          }));

        return quizReadyEpisodeIdsAndQuizCounts;
      },
      providesTags: [PostgrestApiTags.SESSION_QUIZZES],
    }),
    findPlayerSessionByEpisodeId: builder.query<
      FindPlayerSessionByEpisodeIdResponse,
      FindPlayerSessionByEpisodeIdPayload
    >({
      query: fetchSession,
      transformResponse: transformSessionResponseNullable,
    }),
    resumeLastPlayerSession: builder.mutation<
      ResumeLastSessionResponse,
      ResumeLastSessionPayload
    >({
      query: resumeLastPlayerSession,
      transformResponse: transformSessionResponseNullable,
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          const { data } = await queryFulfilled;

          const episodeId = data?.state.episodeId;

          if (!episodeId) return;

          const savedSession =
            playerFsmApi.endpoints.fetchPlayerSessions.select(undefined)(
              getState(),
            )?.data?.entities[episodeId] as UserEpisodeFsm;

          if (savedSession && savedSession.sessionId !== data.sessionId) {
            dispatch(
              postgrestApi.util.invalidateTags([
                PostgrestApiTags.PLAYER_SESSIONS,
              ]),
            );
          }
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    startPlayerSession: builder.mutation<
      StartSessionResponse,
      StartSessionPayload
    >({
      query: startPlayerSession,
      transformResponse: transformSessionResponse,
      onQueryStarted: async (
        { episodeId },
        { dispatch, queryFulfilled, getState },
      ) => {
        try {
          const { data } = await queryFulfilled;
          const savedSession =
            playerFsmApi.endpoints.fetchPlayerSessions.select(undefined)(
              getState(),
            )?.data?.entities[episodeId] as UserEpisodeFsm;

          if (savedSession && savedSession.sessionId !== data.sessionId) {
            dispatch(
              postgrestApi.util.invalidateTags([
                PostgrestApiTags.PLAYER_SESSIONS,
              ]),
            );
          }
        } catch (error) {
          handleOnQueryStartedError(error);
        }
      },
    }),
    playPlayerSession: builder.mutation<
      PlayerSessionTransitionResponse,
      PlaySessionParams
    >({
      query: playPlayerSession,
      transformResponse: transformPlayerTransitionResponse,
    }),
    pausePlayerSession: builder.mutation<
      PlayerSessionTransitionResponse,
      PauseSessionParams
    >({
      query: pausePlayerSession,
      transformResponse: transformPlayerTransitionResponse,
    }),
    setPosition: builder.mutation<
      PlayerSessionTransitionResponse,
      PostSetPositionParams
    >({
      query: setPosition,
      transformResponse: transformPlayerTransitionResponse,
    }),
    seekPosition: builder.mutation<
      PlayerSessionTransitionResponse,
      PostSeekPositionParams
    >({
      query: seekPosition,
      transformResponse: transformPlayerTransitionResponse,
    }),
    answerQuiz: builder.mutation<
      PlayerSessionTransitionResponse,
      AnswerQuizPayload
    >({
      query: answerQuiz,
      transformResponse: transformPlayerTransitionResponse,
      invalidatesTags: [
        PostgrestApiTags.PLAYER_SESSIONS,
        PostgrestApiTags.SESSION_QUIZZES,
      ],
    }),
  }),
});

export const {
  useFetchPlayerConfigQuery,
  useFetchPlayerSessionsQuery,
  useFetchSessionQuizzesQuery,
  useFindPlayerSessionByEpisodeIdQuery,
  useStartPlayerSessionMutation,
  usePlayPlayerSessionMutation,
  useAnswerQuizMutation,
} = playerFsmApi;
