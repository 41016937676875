import { Cancel } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modals } from '@/constants';
import { LoginForm, RegisterForm } from '@/containers';
import { uiActions } from '@/store';

import { AppModal } from '../AppModal';

export const SignupModal = () => {
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(true);

  return (
    <AppModal modalName={Modals.SIGN_UP} disableBackdropClick>
      <IconButton
        sx={styles.close}
        onClick={() => {
          dispatch(uiActions.closeActiveModal());
          setShowLogin(true);
        }}>
        <Cancel />
      </IconButton>
      <Box sx={styles.container}>
        {showLogin ? (
          <LoginForm
            onSignUpClick={() => setShowLogin(false)}
            onLoginSuccess={() => {
              window.location.reload();
            }}
          />
        ) : (
          <RegisterForm
            onLoginClick={() => setShowLogin(true)}
            onRegisterSuccess={() => {
              window.location.reload();
            }}
          />
        )}
      </Box>
    </AppModal>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 5.5,
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    p: 1.5,
    color: 'neutral35',
    cursor: 'pointer',
  },
};
