import { OutlinedInput, Select as MuiSelect } from '@mui/material';

export const Select = ({
  children,
  selectedOptions,
  isOpened,
  onOpen,
  onClose,
  onChange,
  title,
  isMultiselect = false,
}) => (
  <MuiSelect
    sx={styles.dropdownCategory}
    value={selectedOptions}
    renderValue={() => title}
    multiple={isMultiselect}
    displayEmpty
    input={<OutlinedInput />}
    open={isOpened}
    onOpen={onOpen}
    onClose={onClose}
    onChange={onChange}
    MenuProps={{
      MenuListProps: {
        style: {
          padding: 0,
        },
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    }}>
    {children}
  </MuiSelect>
);

const styles = {
  dropdownCategory: theme => ({
    borderRadius: 1.5,
    height: {
      xs: 46,
      sm: 33,
    },
    width: {
      xs: '100%',
      sm: 'auto',
    },
    '& .MuiInputBase-input': theme => theme.typography.select,
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.transparent,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.teal30,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.teal30,
    },
  }),
};
