import { useDispatch, useSelector } from 'react-redux';

import { ChapterStatus, Modals } from '@/constants';
import { EVENT_CONSTANTS } from '@/services';
import {
  playerThunks,
  selectActiveChapterId,
  selectIsEpisodeRedeemRequired,
  selectIsPlayerPlaying,
  uiActions,
  userEpisodesThunks,
} from '@/store';

import { EpisodeChapterTile as EpisodeChapterTileComponent } from './EpisodeChapterTile.component';

export const EpisodeChapterTile = ({
  index,
  chapter,
  userChapter,
  isPrimaryChapter,
  referrer,
}) => {
  const dispatch = useDispatch();

  const isPlayerPlaying = useSelector(selectIsPlayerPlaying);
  const activeChapterId = useSelector(selectActiveChapterId);
  const isEpisodeRedeemRequired = useSelector(state =>
    selectIsEpisodeRedeemRequired(state, chapter.episodeId),
  );

  const chapterStatus = userChapter?.status;
  const isChapterVerifiable = chapter.isVerifiable;

  const progressPercentage =
    ((userChapter?.progress?.playedDuration ?? 0) / chapter.duration) * 100;

  const onShowChapterQuizModal = () => {
    dispatch(
      uiActions.setActiveModal({
        name: Modals.CHAPTER_QUIZ,
        params: {
          episodeId: chapter.episodeId,
          chapterId: chapter.chapterId,
        },
      }),
    );
  };

  const onIconClick = event => {
    event.stopPropagation();
    if (isEpisodeRedeemRequired && isChapterVerifiable && index > 0) {
      dispatch(
        userEpisodesThunks.initiateRedemption({ episodeId: chapter.episodeId }),
      );
    } else if (chapterStatus === ChapterStatus.QUIZ_READY) {
      onShowChapterQuizModal();
    } else {
      dispatch(
        playerThunks.chapterPlayPressed({
          activeChapter: {
            episodeId: chapter.episodeId,
            chapterId: chapter.chapterId,
          },
          location: {
            ...referrer,
            button: EVENT_CONSTANTS.BUTTON.PLAY_CHAPTER,
          },
          referrer,
        }),
      );
    }
  };

  return (
    <EpisodeChapterTileComponent
      index={index}
      onIconClick={onIconClick}
      onTileClick={onShowChapterQuizModal}
      chapterType={chapter.type}
      chapterName={chapter.name}
      chapterStatus={chapterStatus}
      chapterProgressPercentage={progressPercentage}
      isEpisodeRedeemRequired={isEpisodeRedeemRequired}
      isChapterPlaying={
        isPlayerPlaying && activeChapterId === chapter.chapterId
      }
      isChapterVerifiable={isChapterVerifiable}
      isPrimaryChapter={isPrimaryChapter}
    />
  );
};
