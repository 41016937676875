import { Box, Typography } from '@mui/material';

interface CardFlairProps {
  label: string;
  variant?: 'small' | 'large';
}

export const CardFlair = ({ label, variant = 'small' }: CardFlairProps) => {
  const isSmall = variant === 'small';
  return (
    <Box
      sx={{
        display: 'block',
        textAlign: 'center',
        width: isSmall ? 100 : 130,
        position: 'absolute',
        top: isSmall ? 12 : 16,
        right: isSmall ? -26 : -34,
        transform: 'rotate(45deg)',
        background: theme => theme.gradients.orangeDarkToLight,
        color: 'white',
        boxShadow: theme => theme.customShadows.cardFlair,
      }}>
      <Typography
        variant={isSmall ? 'h6' : 'h5'}
        sx={{ lineHeight: 1.8, fontWeight: 700 }}>
        {label}
      </Typography>
    </Box>
  );
};
