import { Box } from '@mui/material';

import { Skeleton } from '@/components';

export const ChapterQuizModalSkeleton = () => (
  <>
    <Box>
      <Skeleton height={40} width={200} />
      <Skeleton />
      <Skeleton />
      <Skeleton width="70%" />
    </Box>
    <Box>
      <Skeleton height={40} width={400} />
      <Skeleton />
      <Skeleton />
      <Skeleton
        width="49%"
        height={110}
        sx={{ mr: '2%', display: 'inline-flex' }}
      />
      <Skeleton width="49%" height={110} sx={{ display: 'inline-flex' }} />
      <Skeleton
        width="49%"
        height={110}
        sx={{ mr: '2%', display: 'inline-flex' }}
      />
      <Skeleton width="49%" height={110} sx={{ display: 'inline-flex' }} />
    </Box>
  </>
);
