import { Box } from '@mui/material';

export const ProgressBar = ({ current, total }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 6,
        backgroundColor: 'neutral10tint1',
        borderRadius: 0.5,
        mb: 1.5,
        overflow: 'hidden',
      }}>
      <Box
        sx={theme => ({
          height: 'inherit',
          width: `${(current / total) * 100}%`,
          background: theme.gradients.primary2,
        })}
      />
    </Box>
  );
};
