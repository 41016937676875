import { Box, Typography } from '@mui/material';

export const CarouselPill = ({ label, onClick }) => {
  return (
    <Box sx={styles.wrapper} onClick={onClick}>
      <Box sx={styles.pill}>
        <Typography variant="body2">{label}</Typography>
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: 'inline-block',
    pr: 1,
    width: 'fit-content',
  },
  pill: {
    border: 1.5,
    borderColor: 'neutral20tint3',
    boxSizing: 'border-box',
    borderRadius: 6,
    py: 1,
    px: 2,
    cursor: 'pointer',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkituserSelect: 'none',
    color: 'neutral35',
    '&:hover': {
      bgcolor: 'neutral10tint1',
    },
  },
};
