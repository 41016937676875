import {
  ArrowBack,
  ArrowForward,
  Close,
  PauseRounded,
  PlayArrowRounded,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as R from 'ramda';

import { AnswerBox, Button, ButtonVariants } from '@/components';
import { QuizAnswer } from '@/types';
import { convertSecondsToMinutes } from '@/utils';

import { ChapterQuizModalSkeleton } from './ChapterQuizModalSkeleton';
import { ChapterQuizModalContentProps } from './types';

export const ChapterQuizModalContent = ({
  onNextPage,
  onPreviousPage,
  onSelectAnswer,
  onSubmitQuiz,
  onPlayChapter,
  onClose,
  totalPageCount,
  selectedPageIndex,
  selectedPage,
  selectedAnswerIndex,
  isChapterCompleted,
  isChapterPlaying,
  isQuizCompleted,
  isDataLoading,
  isSubmitLoading,
  region,
}: ChapterQuizModalContentProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  if (isDataLoading || !selectedPage)
    return (
      <Box sx={styles.modalContainer}>
        <ChapterQuizModalSkeleton />
      </Box>
    );

  const { chapter, quiz, quizCountLabel } = selectedPage || {};

  const chapterDuration = convertSecondsToMinutes(chapter.duration);

  return (
    <Box sx={styles.modalContainer}>
      <Box>
        <Box sx={styles.headerContainer}>
          <Typography variant="h1" color="neutral40">
            Chapter {chapter.chapterPosition}
          </Typography>
          <IconButton
            onClick={onPlayChapter}
            aria-label="play chapter"
            size="small"
            sx={styles.playButton}>
            {isChapterPlaying ? (
              <PauseRounded sx={{ color: 'blue30' }} />
            ) : (
              <PlayArrowRounded sx={{ color: 'blue30' }} />
            )}
          </IconButton>
          <IconButton
            aria-label="close chapter modal"
            onClick={onClose}
            sx={{ ml: 'auto' }}>
            <Close />
          </IconButton>
        </Box>
        <Typography variant="h3" color="neutral35">
          {`${chapterDuration} min${chapterDuration > 1 ? 's' : ''}`}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" color="neutral40">
          {chapter.name}
        </Typography>
        <Typography variant="body1" color="neutral50">
          {chapter.description}
        </Typography>
      </Box>
      {!!quiz && (
        <>
          <Box>
            <Typography variant="h2" color="neutral40">
              {!quizCountLabel ? 'Quiz' : `Quizzes (${quizCountLabel})`}
            </Typography>
            {isChapterCompleted ? (
              <Typography variant="body1" color="neutral50">
                {quiz.question}
              </Typography>
            ) : (
              <Typography variant="body1" color="neutral50">
                Available when chapter is completed
              </Typography>
            )}
          </Box>
          {isChapterCompleted && (
            <Box>
              <Box sx={styles.answerGrid}>
                {quiz.answers.map((answer: QuizAnswer, index: number) => (
                  <Box sx={styles.answerContainer} key={index}>
                    <AnswerBox
                      onSelect={() => onSelectAnswer(index)}
                      isSelected={
                        selectedAnswerIndex === index ||
                        (isQuizCompleted && answer.isCorrect)
                      }
                      answer={answer}
                      index={index}
                      questionId={quiz.quizId}
                      isDisabled={isQuizCompleted || isSubmitLoading}
                    />
                    {isQuizCompleted && region === 'USA' && (
                      <Box
                        sx={[
                          {
                            mt: 1,
                            color: answer.isCorrect ? 'green' : 'red',
                            fontStyle: 'italic',
                          },
                        ]}>
                        <Typography variant="body1">
                          <b>
                            {answer.isCorrect ? 'Correct' : 'Incorrect'}:&nbsp;
                          </b>
                          {answer.caption}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
      <Box>
        <Box sx={styles.bottomRow}>
          <Box>
            <Button
              onClick={onPreviousPage}
              label={isMobile ? undefined : 'Previous'}
              disabled={selectedPageIndex === 0}
              startIcon={<ArrowBack />}
              variant={ButtonVariants.TERTIARY}
              fullWidth={false}
              skinny={false}
              sx={{ mr: 1 }}
            />
            <Button
              onClick={onNextPage}
              label={isMobile ? undefined : 'Next'}
              disabled={selectedPageIndex === totalPageCount - 1}
              endIcon={<ArrowForward />}
              variant={ButtonVariants.TERTIARY}
              fullWidth={false}
              skinny={false}
            />
          </Box>
          <Box>
            {isQuizCompleted &&
              !R.isNil(selectedPageIndex) &&
              selectedPageIndex < totalPageCount - 1 && (
                <Button
                  onClick={onNextPage}
                  label={'Continue'}
                  disabled={isSubmitLoading}
                  variant={ButtonVariants.PRIMARY}
                  fullWidth={false}
                  skinny={false}
                />
              )}
            {!isQuizCompleted && !!quiz && (
              <Button
                onClick={onSubmitQuiz}
                label={'Submit'}
                disabled={
                  !isChapterCompleted ||
                  R.isNil(selectedAnswerIndex) ||
                  isSubmitLoading
                }
                variant={ButtonVariants.PRIMARY}
                fullWidth={false}
                skinny={false}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: {
      xs: 'auto',
      md: 700,
      lg: 900,
    },
    gap: 4,
    py: 4,
    px: {
      xs: 4,
      sm: 6,
    },
  },
  answerGrid: {
    display: 'grid',
    gridTemplateColumns: { md: '1fr 1fr' },
    gap: 2,
  },
  answerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  playButton: {
    border: 1.5,
    borderColor: 'blue30',
    ml: 2,
  },
};
